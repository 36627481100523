import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { find } from 'lodash';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { validateEmail } from '../Helpers/DataValidators';
import { clearInterval, setInterval } from 'worker-timers';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '7px',
  },
  width100: {
    width: '100%',
  },
  removeButton: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

const FormMode = [
  {
    id: 1,
    title: 'Enter Email',
  },
  {
    id: 2,
    title: 'Verify Email',
  },
  {
    id: 3,
    title: 'Select Source Company and Investment Opportunity Profile (IOP)',
  },
];

export default function EditIOPPopup({
  open,
  setOpen,
  backdropCSS,
  loader,
  sendOTP,
  verifyOTP,
  getSEList,
  getProjectList,
  getExistingExternalCampaignMeta,
  getIOPMetaInfo,
  handleSnackbar,
}) {
  // minutes * seconds
  const otpResendTimer = 2 * 60;
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otp, setOTP] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [buttonText, setButtonText] = useState('Send OTP');
  const [seList, setSEList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [selectedSE, setSelectedSE] = useState('');

  const [selectedIOP, setSelectedIOP] = useState(null);
  const [timeInSeconds, setTime] = useState(otpResendTimer);

  const handleTimer = () => {
    let timer = setInterval(() => {
      setTime(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  };

  const getCurrentCountdown = () => {
    return timeInSeconds !== 0
      ? `${Math.floor(timeInSeconds / 60)}`.padStart(2, 0) +
          ':' +
          `${timeInSeconds % 60}`.padStart(2, 0)
      : '';
  };

  const handleSendOTP = async () => {
    const isOTPSent = await sendOTP(email);
    if (isOTPSent) {
      setFormStep(2);
      setButtonText('Verify OTP');
      setShowOTP(true);
      setTime(otpResendTimer);
      handleTimer();
    }
  };

  const handleVerifyOTP = async () => {
    const isOTPVerified = await verifyOTP(email, otp);
    if (isOTPVerified) {
      const seListByEmail = await getSEList(email);
      setSEList(seListByEmail);
      setIsOTPVerified(true);
      setFormStep(3);
      setButtonText('Edit');
      setShowOTP(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    if (selectedSE != '') {
      const projectBySE = await getProjectList(selectedSE);
      setProjectList(projectBySE);
    }
  }, [selectedSE]);
  // changes added
  const startEditWWH = () => {
    getExistingExternalCampaignMeta(
      selectedSE,
      selectedIOP?.projectID,
      selectedIOP?.isComplete
    );
    handleClose();
  };
  // changes added

  const createNewIOPtoExistingSE = async () => {
    const result = await getIOPMetaInfo(selectedSE);
    if (result) {
      handleClose();
      handleSnackbar(
        'success',
        'Investment Opportunity Profile created successfully!'
      );
    } else {
      handleSnackbar('error', 'error');
    }
  };

  const body = (
    <Grid container spacing={1} className={classes.width100}>
      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <TextField
          disabled={isOTPVerified || formStep > 1}
          error={!validateEmail(email) && email != '' ? true : false}
          type="email"
          id="standard-required"
          name="email"
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className={classes.width100}
          helperText={
            !validateEmail(email) && email != '' && 'Please enter valid email'
          }
        />
      </Grid>

      {showOTP && (
        <>
          <Grid item md={7} xs={12} sm={7} style={{ paddingRight: '0px' }}>
            <TextField
              type="text"
              error={otp == '' ? true : false}
              id="standard-required"
              name="otp"
              label="Enter OTP"
              value={otp}
              onChange={e => setOTP(e.target.value?.trim())}
              className={classes.width100}
              helperText={otp == '' && 'Please enter valid OTP'}
            />
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sm={5}
            style={{
              paddingRight: '0px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              disabled={timeInSeconds !== 0 || formStep > 2}
              onClick={() => {
                handleSendOTP();
              }}
              className="btn-primary"
              variant="contained"
            >
              Resend OTP {timeInSeconds !== 0 && `(${getCurrentCountdown()})`}
            </Button>
          </Grid>
        </>
      )}

      {formStep > 2 && (
        <>
          <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
            <Autocomplete
              options={seList}
              getOptionLabel={option => `${option.name}`}
              id="sourceEntity"
              value={find(seList, {
                id: selectedSE,
              })}
              onChange={(event, newValue) => {
                setSelectedSE(newValue?.sourceEntityID || '');
                setSelectedIOP(null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Source Company *"
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item md={8} xs={12} sm={8} style={{ paddingRight: '0px' }}>
            <Autocomplete
              disabled={selectedSE == ''}
              options={projectList}
              getOptionLabel={option =>
                option.projectTitle == null
                  ? '--No name--'
                  : `${option.projectTitle}`
              }
              id="sourceEntity"
              value={selectedIOP}
              onChange={(event, newValue) => {
                setSelectedIOP(newValue);
              }}
              renderInput={params => (
                <TextField {...params} label="Select IOP *" margin="normal" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '15px',
              }}
            >
              <Button
                disabled={selectedSE == '' || selectedIOP != null}
                onClick={createNewIOPtoExistingSE}
                className="btn-primary"
                variant="contained"
              >
                Create new IOP
              </Button>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">
          {FormMode[formStep - 1]?.title}
        </DialogTitle>
        <DialogContent dividers={'paper'}>{body}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="btn-primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={
              (formStep === 1 && !validateEmail(email)) ||
              (formStep === 2 && (otp == '' || otp == null)) ||
              (formStep === 3 && (selectedIOP == null || selectedSE == ''))
            }
            onClick={() => {
              if (formStep === 1) {
                handleSendOTP();
              } else if (formStep === 2) {
                handleVerifyOTP();
              } else if (formStep === 3) {
                startEditWWH();
              }
            }}
            className="btn-primary"
            variant="contained"
          >
            {buttonText}
          </Button>
        </DialogActions>
        <div>
          <Backdrop className={backdropCSS} open={loader}>
            <CircularProgress color="secondary" />
          </Backdrop>
        </div>
      </Dialog>
    </div>
  );
}
