import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    justifyContent: 'space-between',
    // height: '65px',
  },
  filePickerButton: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(0, 174, 200)',
      color: '#fff',
    },
  },
  hintText: {
    fontSize: '12px',
    color: '#aaa',
  },
  imageContainer: {
    width: '200px',
    marginBottom: '0px',
    marginTop: '5px',
    borderRadius: '10px',
  },
  blockDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    backgroundColor: '#2c3b8f',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(0, 174, 200)',
      color: '#fff',
    },
  },
}));

const MultiFilePicker = ({
  label = 'Choose File',
  maxFileSizeInMB = 1,
  onChange,
  isReadOnlyMode,
}) => {
  const classes = useStyles();
  const [theInputKey, setTheInputKey] = useState(null);

  const verifyFileFormat = fileName => {
    const allowedFileTypes = ['pdf', 'docx', 'xlsx'];

    if (allowedFileTypes.includes(fileName.split('.').pop())) {
      return true;
    }
  };

  const onChangePicker = file => {
    const { files } = file.target;

    if (files.length > 0) {
      const fileName = files.item(0).name;
      const fileSize = files.item(0).size;
      const fileSizeMb = fileSize / 1024 ** 2;

      if (!verifyFileFormat(fileName)) {
        alert('Please upload pdf, docx, or excel file only!');
      } else if (fileSizeMb > maxFileSizeInMB) {
        alert(`Too big! Select a file under ${maxFileSizeInMB} MB!`);
        // input.value = '';
        return false;
      } else {
        // setImageDetails(files.item(0));
        onChange(files.item(0));
      }

      let randomString = Math.random().toString(36);

      setTheInputKey(randomString);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          <Button
            component="label"
            className={
              isReadOnlyMode ? classes.blockDisabled : classes.filePickerButton
            }
            //  className={classes.filePickerButton}
          >
            {label}
            <input
              type="file"
              accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword"
              hidden
              onChange={onChangePicker}
              key={theInputKey || ''}
            />
          </Button>
        </div>
      </div>
      <span className={classes.hintText}>
        Max file size allowed: {maxFileSizeInMB} MB
      </span>
    </>
  );
};

export default MultiFilePicker;
