import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { create } from 'apisauce';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Input,
  ListItemText,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Constants from '../../../static/Constants';
import { find, remove, filter, isEmpty } from 'lodash';
import FilePicker from '../FilePicker/FilePicker';
import { convertBase64 } from '../../shared/base64Convertor';
import StepTwoForm from './StepTwoForm';
import { populateSEGeneralObject } from './Helpers/DataFormatters';
import { trimString, validateSEGeneralForm } from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '15px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
  table: {
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
  },
}));

const ownershipType = [
  { value: '0', label: 'Foreign' },
  { value: '1', label: 'Domestic' },
];

function StepOneForm({
  loading,
  countryList,
  externalCampaignMeta,
  setStep,
  handleSnackbar,
  backdropCSS,
  isEditMode,
  selectedCountry,
  setSelectedCountry,
  setIsNewRecord,
  isNewRecord,
  handleScroll,
  isReadOnlyMode,
}) {
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const classes = useStyles();
  const [formData, setFormData] = useState({
    sourceEntityTypeID: '1',
    name: '',
    companyType: '1',
    countryID: '',
    regionID: '',
    otherRegion: '',
    cityID: '',
    otherCity: '',
    postalCode: '',
    address: '',
    externalReference: '',
    sourceLegalStatusID: '',
    website: '',
    sourceEntityLanguage: [],
    yearOfEstablishment: '',
    isSEZ: 0,
    isISO9000: 0,
    isHACCP: 0,
    sourceEntityContact: [],
    parentSourceEntityID: null,
    sourceEntityPurposeId: 1,
    sourceEntityID: externalCampaignMeta?.sourceEntityID,
    latitude: '',
    longitude: '',
    sourceEntityLogo: [{}],
    sourceEntityLogoThumbnail: [
      {
        sourceEntityID: 0,
        sourceEntityLogoThumbnailSource: '',
        isPrimaryThumbnail: true,
      },
    ],
  });

  const [regionListLoader, setRegionListLoader] = useState(false);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [langLegalLoader, setLangLegalLoader] = useState(false);
  const [contactTypeListLoader, setContactTypeListLoader] = useState(false);
  const [SourceEntityGeneralLoader, setSourceEntityGeneralLoader] = useState(
    false
  );
  const [sourceRepPopup, setSourceRepPopup] = useState(false);
  const [minSourceRepDetails, setMinSourceRepDetails] = useState({});
  const [errorState, setErrorState] = useState({});
  const [sourceEntityLanguage, setSourceEntityLanguage] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [legalStatusList, setLegalStatusList] = useState([]);
  const [countryIDSelected, setCountryIDSelected] = useState(
    selectedCountry || ''
  );
  const [regionList, setRegionList] = useState([]);
  const [regionIdSelected, setRegionIdSelected] = useState('');
  const [cityList, setCityList] = useState([]);
  const [cityLatLonList, setCityLatLonList] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);

  const [emailList, setEmailList] = useState([
    {
      contactTypeID: '',
      contactDetail: '',
    },
  ]);
  const [telephoneList, setTelephoneList] = useState([
    {
      contactTypeID: '',
      contactDetail: '',
    },
  ]);
  const [socialList, setSocialList] = useState([]);
  const [seImage, setSEImage] = useState({
    sourceEntityLogo: [{}],
    sourceEntityLogoThumbnail: [
      {
        sourceEntityID: 0,
        sourceEntityLogoThumbnailSource: '',
        isPrimaryThumbnail: true,
      },
    ],
  });

  useEffect(() => {
    const cityDetails = cityLatLonList.find(
      city => city.id === formData.cityID
    );

    setFormData({
      ...formData,
      latitude: Number(cityDetails?.latitude) || null,
      longitude: Number(cityDetails?.longitude) || null,
    });
  }, [formData.cityID, cityLatLonList]);

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     companyType: formData.sourceEntityTypeID,
  //   });
  // }, [formData.sourceEntityTypeID]);

  useEffect(() => {
    if (countryIDSelected) {
      setRegionListLoader(true);
      apiSauceInstance
        .get(`${Constants.getRegionList}/${countryIDSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const regionListNew = data.map(region => {
              return {
                value: region.regionID,
                label: region.regionName,
              };
            });
            setRegionList(regionListNew);
          } else {
            //error do something
          }

          setRegionListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setRegionListLoader(false);
        });
    }
  }, [countryIDSelected]);

  useEffect(() => {
    if (regionIdSelected) {
      setCityListLoader(true);
      apiSauceInstance
        .get(`${Constants.getCityList}/${regionIdSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const cityListNew = data.map(city => {
              return {
                value: city.cityID,
                label: city.cityName,
              };
            });
            const cityLatLonListNew = data.map(city => {
              return {
                id: city.cityID,
                latitude: city.cityLatitude,
                longitude: city.cityLongitude,
              };
            });
            setCityList(cityListNew);
            setCityLatLonList(cityLatLonListNew);
          } else {
            //error do something
          }

          setCityListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setCityListLoader(false);
        });
    }
  }, [regionIdSelected]);

  useEffect(() => {
    setCountryIDSelected(formData.countryID);
    setSelectedCountry(formData.countryID);
  }, [formData.countryID]);

  useEffect(() => {
    if (countryIDSelected !== '') {
      setLangLegalLoader(true);
      Promise.all([
        apiSauceInstance.get(
          `${Constants.getLegalStatusList}/${countryIDSelected}`
        ),
        apiSauceInstance.get(
          `${Constants.getLanguageList}/${countryIDSelected}`
        ),
      ])
        .then(([legalResponse, languageResponse]) => {
          if (
            (legalResponse.status === 200 || legalResponse.status === 201) &&
            (languageResponse.status === 200 || languageResponse.status === 201)
          ) {
            const languageData = languageResponse.data;
            const legalData = legalResponse.data;

            const languageListNew = languageData.map(language => ({
              value: language.languageID,
              label: language.languageName,
            }));
            const legalListNew = legalData.map(legalStatus => {
              return {
                value: legalStatus.sourceLegalStatus.sourceLegalStatusID,
                label: legalStatus.sourceLegalStatus.sourceLegalStatusName,
              };
            });

            setLanguageList(languageListNew);
            setLegalStatusList(legalListNew);
          } else {
            //error do something
          }

          setLangLegalLoader(false);
        })
        .catch(error => {
          // Handle errors
          if (error) {
            // do something
          }

          setLangLegalLoader(false);
        });
    }
  }, [countryIDSelected]);

  useEffect(() => {
    setContactTypeListLoader(true);
    Promise.all([apiSauceInstance.get(`${Constants.getContactTypes}`)])
      .then(([contactTypeRespnse]) => {
        if (
          contactTypeRespnse.status === 200 ||
          contactTypeRespnse.status === 201
        ) {
          const contactTypeData = contactTypeRespnse.data;

          const contactTypeListNew = contactTypeData.map(contactType => ({
            value: contactType.contactTypeID,
            label: contactType.contactTypeName,
          }));

          setContactTypeList(contactTypeListNew);
        } else {
          //error do something
        }

        setContactTypeListLoader(false);
      })
      .catch(error => {
        // Handle errors
        if (error) {
          // do something
        }

        setContactTypeListLoader(false);
      });
  }, []);

  useEffect(() => {
    const sourceEntityId = externalCampaignMeta?.sourceEntityID;
    if (sourceEntityId !== '' && !isEmpty(contactTypeList)) {
      setSourceEntityGeneralLoader(true);
      Promise.all([
        apiSauceInstance.get(
          `${Constants.getSourceEntityGeneral}/${sourceEntityId}`
        ),
      ])
        .then(([sourceEntityGeneralResponse]) => {
          if (
            sourceEntityGeneralResponse.status === 200 ||
            sourceEntityGeneralResponse.status == 201
          ) {
            // const contactTypeData =contactTypeList;
            const sourceEntityGeneralData = sourceEntityGeneralResponse.data;

            const contactTypeListNew = contactTypeList;
            setContactTypeList(contactTypeListNew);

            const formattedFormDataGeneralGet = populateSEGeneralObject(
              sourceEntityGeneralData
            );

            if (formattedFormDataGeneralGet.isError) {
              handleSnackbar('error', 'error');
              setSourceEntityGeneralLoader(false);
              return;
            }

            const formDataGeneralGet = formattedFormDataGeneralGet.result;

            const selectedLanguages = formDataGeneralGet.sourceEntityLanguage.map(
              languageObj => {
                return languageObj.languageID;
              }
            );
            let emailID = contactTypeListNew.find(tele => tele.label == 'Email')
              ?.value;

            const emailListTemp = formDataGeneralGet.sourceEntityContact.filter(
              contactObj => contactObj.contactTypeID == emailID
            );
            let telephoneID = contactTypeListNew.find(
              tele => tele.label == 'Telephone'
            )?.value;

            const telephoneListTemp = formDataGeneralGet.sourceEntityContact.filter(
              contactObj => contactObj.contactTypeID == telephoneID
            );

            const socialListTemp = formDataGeneralGet.sourceEntityContact.filter(
              contactObj =>
                contactObj.contactTypeID !== telephoneID &&
                contactObj.contactTypeID !== emailID
            );

            setEmailList(emailListTemp);
            setTelephoneList(telephoneListTemp);
            setSocialList(socialListTemp);
            setSourceEntityLanguage(selectedLanguages);

            setFormData({
              ...formDataGeneralGet,
              isSEZ: formDataGeneralGet.isSEZ == true ? 1 : 0,
              isISO9000: formDataGeneralGet.isISO9000 == true ? 1 : 0,
              isHACCP: formDataGeneralGet.isHACCP == true ? 1 : 0,
              sourceEntityTypeID: String(formDataGeneralGet.sourceEntityTypeID),
              sourceEntityID: externalCampaignMeta?.sourceEntityID,
              parentSourceEntityID: null,
              ...(isNewRecord ? { name: '' } : {}),
              // ...(isEditMode ? {} : { companyType: '1' }),
              ...(formDataGeneralGet.companyType == null
                ? { companyType: '1' }
                : {}),
            });

            const SEContact =
              sourceEntityGeneralData.sourceEntityRepresentative[0];

            setMinSourceRepDetails({
              firstName: SEContact.firstName,
              lastName: SEContact.lastName,
              designation: SEContact.designation,
            });

            setSEImage({
              sourceEntityLogo: [{}],
              sourceEntityLogoThumbnail: [
                {
                  isPrimaryThumbnail:
                    sourceEntityGeneralData?.sourceEntityLogoThumbnail[0]
                      ?.isPrimaryThumbnail || true,
                  sourceEntityID:
                    sourceEntityGeneralData?.sourceEntityLogoThumbnail[0]
                      ?.sourceEntityID || 0,
                  sourceEntityLogoThumbnailSource:
                    sourceEntityGeneralData?.sourceEntityLogoThumbnail[0]
                      ?.sourceEntityLogoThumbnailSource || '',
                },
              ],
              // sourceEntityGeneralData?.sourceEntityLogoThumbnail,
            });
          } else {
            //error do someting
          }

          setSourceEntityGeneralLoader(false);
        })
        .catch(error => {
          // Handle errors
          if (error) {
            // do something
          }

          setSourceEntityGeneralLoader(false);
        });
    }
  }, [externalCampaignMeta?.sourceEntityID, contactTypeList]);

  useEffect(() => {
    setRegionIdSelected(formData.regionID);
  }, [formData.regionID]);

  useEffect(() => {
    setFormData({
      ...formData,
      sourceEntityContact: [...telephoneList, ...emailList, ...socialList],
    });
  }, [emailList, telephoneList, socialList]);

  const addEmail = () => {
    let tempEmailList = [
      ...emailList,
      {
        contactTypeID: '',
        contactDetail: '',
      },
    ];

    setEmailList(tempEmailList);
  };

  const removeEmail = index => {
    let tempEmailList = [...emailList];
    tempEmailList.splice(index, 1);

    setEmailList(tempEmailList);
  };

  const handleEmailChange = (index, event) => {
    let emailID = contactTypeList.find(tele => tele.label == 'Email')?.value;
    const newInputs = [...emailList];
    newInputs[index].contactDetail = event.target.value;
    newInputs[index].contactTypeID = emailID;

    let errors = { ...errorState };
    remove(errors?.emailList, {
      errorIndex: index,
    });

    if (errors?.emailList?.length == 0) {
      delete errors?.['emailList'];
    }

    setErrorState(errors);

    setEmailList(newInputs);
  };

  const addTelephone = () => {
    let tempTelephoneList = [
      ...telephoneList,
      {
        contactTypeID: '',
        contactDetail: '',
      },
    ];

    setTelephoneList(tempTelephoneList);
  };

  const removeTelephone = index => {
    let tempTelephoneList = [...telephoneList];
    tempTelephoneList.splice(index, 1);

    setTelephoneList(tempTelephoneList);
  };

  const handleTelephoneChange = (index, event) => {
    let telephoneID = contactTypeList.find(tele => tele.label == 'Telephone')
      ?.value;

    const newInputs = [...telephoneList];
    newInputs[index].contactDetail = trimString(event.target.value);
    newInputs[index].contactTypeID = telephoneID;

    let errors = { ...errorState };
    remove(errors?.telephoneList, {
      errorIndex: index,
    });

    if (errors?.telephoneList?.length == 0) {
      delete errors?.['telephoneList'];
    }

    setErrorState(errors);

    setTelephoneList(newInputs);
  };

  const addSM = () => {
    let tempSMList = [
      ...socialList,
      {
        contactDetail: '',
        contactTypeID: null,
        mediaTypeDescription: '',
        mediaTypeID: null,
      },
    ];

    setSocialList(tempSMList);
  };

  const removeSM = index => {
    let tempSMList = [...socialList];
    tempSMList.splice(index, 1);

    setSocialList(tempSMList);

    let errors = { ...errorState };

    remove(errors?.socialList, {
      errorIndex: index,
    });

    remove(errors?.socialHandleList, {
      errorIndex: index,
    });

    if (errors?.socialList?.length == 0) {
      delete errors?.['socialList'];
    }

    if (errors?.socialHandleList?.length == 0) {
      delete errors?.['socialHandleList'];
    }

    setErrorState(errors);
  };

  const handleSocialChange = (index, event) => {
    const fieldName = event.target.name;

    const newInputs = [...socialList];

    if (fieldName == 'id') {
      newInputs[index].contactDetail = event.target.value;
    } else {
      newInputs[index].contactTypeID = event.target.value;
      newInputs[index].mediaTypeID = event.target.value;
      newInputs[index].mediaTypeDescription = find(contactTypeList, {
        value: event.target.value,
      })?.label;
    }

    let errors = { ...errorState };

    remove(errors?.socialList, {
      errorIndex: index,
    });

    remove(errors?.socialHandleList, {
      errorIndex: index,
    });

    if (errors?.socialList?.length == 0) {
      delete errors?.['socialList'];
    }

    if (errors?.socialHandleList?.length == 0) {
      delete errors?.['socialHandleList'];
    }

    setErrorState(errors);

    setSocialList(newInputs);
  };

  const handleTrim = e => {
    const { name, value } = e.target;
    if (
      name == 'externalReference' ||
      name == 'address' ||
      name == 'name' ||
      name == 'otherRegion' ||
      name == 'otherCity'
    ) {
      setFormData({
        ...formData,
        [name]: trimString(value),
      });
    } else if (name == 'website') {
      setFormData({
        ...formData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    let errors = { ...errorState };
    delete errors[name];
    setErrorState(errors);

    if (name == 'countryID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);
      setFormData({
        ...formData,
        [name]: value,
        regionID: '',
        otherRegion: '',
        cityID: '',
        otherCity: '',
      });
      return;
    }

    if (name == 'cityID') {
      delete errors.otherCity;
      setErrorState(errors);
      setFormData({
        ...formData,
        otherCity: '',
      });
    }

    if (name == 'regionID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);

      setFormData({
        ...formData,
        [name]: value,
        cityID: '',
        otherCity: '',
        otherRegion: '',
      });
      return;
    }

    if (name == 'isSEZ' || name == 'isISO9000' || name == 'isHACCP') {
      setFormData({
        ...formData,
        [name]: Number(value),
      });
    } else if (name == 'sourceEntityLanguage') {
      setSourceEntityLanguage(value);
      const stucturedSourceEntityLanguage = value.map(id => {
        return {
          languageID: id,
        };
      });
      setFormData({
        ...formData,
        [name]: stucturedSourceEntityLanguage,
      });
    } else if (
      name == 'externalReference' ||
      name == 'address' ||
      name == 'name'
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formValidate = () => {
    const errorObj = validateSEGeneralForm(
      formData,
      emailList,
      telephoneList,
      socialList
    );

    if (!(formData.regionID == 999)) {
      delete errorObj.otherRegion;
    }

    if (!(formData.cityID == 9999)) {
      delete errorObj.otherCity;
    }

    let errors = { ...errorObj };

    if (isEmpty(minSourceRepDetails)) {
      errors = {
        ...errors,
        sourceEntityRepresentative:
          'Please enter Source Company Representative details',
      };
    }

    setErrorState(errors);

    if (Object.keys(errors).length == 0) {
      handleSave();
    } else {
      handleScroll(0, 0);
      handleSnackbar('warning', 'validationFail');
    }
  };

  useEffect(() => {
    let errors = { ...errorState };

    if (!isEmpty(minSourceRepDetails)) {
      delete errors?.['sourceEntityRepresentative'];
    }

    setErrorState(errors);
  }, [minSourceRepDetails]);

  const handleSave = () => {
    handleScroll(0, 0);
    if (isReadOnlyMode) {
      setStep(2);
    } else {
      setSourceEntityGeneralLoader(true);
      apiSauceInstance
        .put(
          `${Constants.putSourceEntityGeneral}/${externalCampaignMeta?.sourceEntityID}`,
          {
            ...formData,
            ...seImage,
            sourceEntityTypeID: Number(formData.sourceEntityTypeID),
            companyType: Number(formData.companyType),
          }
        )
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            handleSnackbar('success', 'success');
            setStep(2);
          } else {
            handleSnackbar('error', 'error');
          }
          setIsNewRecord(false);
          setSourceEntityGeneralLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setSourceEntityGeneralLoader(false);
          handleSnackbar('error', 'error');
        });
    }

    // }
  };

  const onSEImageChange = async image => {
    const base64Conversion = image ? await convertBase64(image) : '';

    setSEImage({
      sourceEntityLogo: [{}],
      sourceEntityLogoThumbnail: [
        {
          sourceEntityID: 0,
          sourceEntityLogoThumbnailSource: base64Conversion,
          isPrimaryThumbnail: true,
        },
      ],
    });
  };

  return (
    <div className="container" style={{ marginTop: '20px' }}>
      {/* <FormControl component="legend" className={classes.width100}> */}
      <Grid
        container
        className={classes.width100}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item md={12} xs={12} sm={12}>
          <FormLabel
            component="legend"
            style={{
              marginTop: '20px',
              fontWeight: '700',
            }}
          >
            Entity Type
          </FormLabel>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <RadioGroup
            disabled={isReadOnlyMode}
            aria-label="Gender"
            name="sourceEntityTypeID"
            value={formData.sourceEntityTypeID}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio disabled={isReadOnlyMode} />}
              label="Company"
            />
            <FormControlLabel
              value="3"
              control={<Radio disabled={isReadOnlyMode} />}
              label="Organization"
            />
            <FormControlLabel
              value="2"
              control={<Radio disabled={isReadOnlyMode} />}
              label="Project Concept"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {/* </FormControl> */}

      <Grid container spacing={4} className={classes.width100}>
        <Grid
          item
          md={formData.sourceEntityTypeID == 1 ? 9 : 12}
          xs={12}
          sm={formData.sourceEntityTypeID == 1 ? 9 : 12}
          style={{ paddingRight: '0px' }}
        >
          <TextField
            disabled={isReadOnlyMode}
            required
            id="standard-required"
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleTrim}
            margin="normal"
            defaultValue="Hello World"
            className={classes.width100}
            inputProps={{ maxLength: 250 }}
          />
          {errorState?.name ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.name}
            </span>
          ) : (
            ''
          )}
        </Grid>
        {formData.sourceEntityTypeID == 1 ? (
          <Grid item md={3} xs={12} sm={3} style={{ paddingRight: '0px' }}>
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
            >
              <InputLabel id="demo-simple-select-label">
                Ownership Type *
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                required
                name="companyType"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.companyType}
                margin="normal"
                onChange={handleChange}
              >
                {ownershipType.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ''
        )}
        <Grid item md={3} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
            >
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                disabled={isEditMode && isReadOnlyMode}
                name="countryID"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.countryID}
                margin="normal"
                onChange={handleChange}
              >
                {countryList?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.countryID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.countryID}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={3} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
              disabled={countryIDSelected === ''}
            >
              <InputLabel
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
              >
                Region *
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="regionID"
                label="Region"
                labelId={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select'
                    : 'demo-simple-select-disabled'
                }
                value={formData.regionID}
                margin="normal"
                onChange={handleChange}
              >
                {regionList.map(region => (
                  <MenuItem key={region.value} value={region.value}>
                    {region.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.regionID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.regionID}
              </span>
            ) : (
              ''
            )}
            {formData.regionID === 999 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherRegion"
                label="Enter Region Name"
                value={formData.otherRegion}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                className={classes.width100}
                inputProps={{ maxLength: 50 }}
              />
            ) : (
              ''
            )}
            {errorState?.otherRegion ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherRegion}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>
        <Grid item md={3} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
              disabled={regionIdSelected === ''}
            >
              <InputLabel
                id={
                  regionIdSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
              >
                City *
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="cityID"
                label="City"
                labelId={
                  regionIdSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
                id={
                  regionIdSelected !== ''
                    ? 'demo-simple-select'
                    : 'demo-simple-select-disabled'
                }
                value={formData.cityID}
                margin="normal"
                onChange={handleChange}
              >
                {cityList.map(city => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.cityID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.cityID}
              </span>
            ) : (
              ''
            )}
            {formData.cityID === 9999 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherCity"
                label="Enter City Name"
                value={formData.otherCity}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                className={classes.width100}
                inputProps={{ maxLength: 50 }}
              />
            ) : (
              ''
            )}
            {errorState?.otherCity ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherCity}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={3} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <TextField
              disabled={isReadOnlyMode}
              required
              id="standard-required"
              name="postalCode"
              label="Postal Code"
              value={formData.postalCode}
              onChange={handleChange}
              margin="normal"
              className={classes.width100}
            />
            {errorState?.postalCode ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.postalCode}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={6} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            id="standard-required"
            name="address"
            label="Address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleTrim}
            margin="normal"
            className={classes.width100}
            inputProps={{ maxLength: 250 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {formData?.address?.length}/250
          </span> */}
        </Grid>

        <Grid
          item
          md={formData.sourceEntityTypeID == 1 ? 3 : 6}
          xs={12}
          sm={formData.sourceEntityTypeID == 1 ? 6 : 12}
          style={{ paddingRight: '0px' }}
        >
          <TextField
            disabled={isReadOnlyMode}
            id="standard-required"
            name="externalReference"
            label="External Reference"
            value={formData.externalReference}
            onChange={handleChange}
            onBlur={handleTrim}
            margin="normal"
            className={classes.width100}
            inputProps={{ maxLength: 250 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {formData?.externalReference?.length}/250
          </span> */}
        </Grid>
        {formData.sourceEntityTypeID == 1 ? (
          <Grid
            item
            md={formData.sourceEntityTypeID == 1 ? 3 : 6}
            xs={12}
            sm={formData.sourceEntityTypeID == 1 ? 6 : 6}
            style={{ paddingRight: '0px' }}
          >
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
              disabled={countryIDSelected === '' || legalStatusList.length == 0}
            >
              <InputLabel
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
              >
                Legal Status
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="sourceLegalStatusID"
                labelId={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select'
                    : 'demo-simple-select-disabled'
                }
                value={formData.sourceLegalStatusID}
                margin="normal"
                onChange={handleChange}
              >
                {legalStatusList.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ''
        )}

        <Grid
          item
          md={6}
          xs={12}
          sm={formData.sourceEntityTypeID == 1 ? 12 : 6}
          style={{ paddingRight: '0px' }}
        >
          <div>
            <TextField
              disabled={isReadOnlyMode}
              required
              id="standard-required"
              name="website"
              label="Website"
              value={formData.website}
              onChange={handleChange}
              onBlur={handleTrim}
              margin="normal"
              className={classes.width100}
            />
            {errorState?.website ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.website}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>
        <Grid
          item
          md={formData.sourceEntityTypeID == 1 ? 3 : 6}
          xs={12}
          sm={formData.sourceEntityTypeID == 1 ? 6 : 6}
          style={{ paddingRight: '0px' }}
        >
          <FormControl
            className={classes.width100}
            style={{ marginTop: '16px' }}
            disabled={countryIDSelected === ''}
          >
            <InputLabel id="demo-mutiple-checkbox-label">Language</InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="sourceEntityLanguage"
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={sourceEntityLanguage}
              onChange={handleChange}
              input={<Input />}
              // renderValue={selected => selected.join(', ')}
              renderValue={selected => {
                const tempArr = [];
                selected.forEach(selectItem => {
                  const lanObj = languageList.find(
                    obj => obj.value == selectItem
                  );
                  tempArr.push(lanObj?.label);
                });

                return tempArr.join(', ');
              }}
            >
              {languageList?.map(name => (
                <MenuItem key={name.value} value={name.value}>
                  <Checkbox
                    checked={sourceEntityLanguage?.indexOf(name.value) > -1}
                  />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {formData.sourceEntityTypeID == 1 ? (
          <Grid
            item
            md={formData.sourceEntityTypeID == 1 ? 3 : 12}
            xs={12}
            sm={formData.sourceEntityTypeID == 1 ? 6 : 12}
            style={{ paddingRight: '0px' }}
          >
            <TextField
              disabled={isReadOnlyMode}
              required
              InputProps={{
                inputProps: {
                  max: new Date().getFullYear(),
                  min: 1600,
                },
              }}
              id="standard-required"
              name="yearOfEstablishment"
              label="Year Of Establishment"
              value={formData.yearOfEstablishment}
              onChange={handleChange}
              margin="normal"
              className={classes.width100}
            />
            {errorState?.yearOfEstablishment ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.yearOfEstablishment}
              </span>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          ''
        )}
        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          Email
          <IconButton
            disabled={isReadOnlyMode}
            onClick={() => addEmail()}
            className={classes.addButton}
            variant="contained"
          >
            <AddCircleIcon />
          </IconButton>
          {emailList.map((value, index) => {
            return (
              <>
                <div key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled={isReadOnlyMode}
                      key={index}
                      required
                      id="standard-required"
                      name="email"
                      label="Email"
                      value={emailList[index]?.contactDetail}
                      onChange={event => handleEmailChange(index, event)}
                      margin="normal"
                      className={classes.width100}
                    />
                    {emailList.length > 0 && (
                      <IconButton
                        disabled={isReadOnlyMode}
                        onClick={() => removeEmail(index)}
                        variant="contained"
                      >
                        <HighlightOffIcon
                          style={{ color: isReadOnlyMode ? '#FFBABC' : 'red' }}
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                  </div>

                  {errorState?.emailList
                    ?.filter(errorObj => errorObj.errorIndex === index)
                    .map((obj, errorIndex) => (
                      <span
                        style={{ fontSize: '12px', color: 'red' }}
                        key={errorIndex}
                      >
                        {obj.errorMessage}
                      </span>
                    ))}
                </div>
              </>
            );
          })}
        </Grid>

        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          Telephone
          <IconButton
            disabled={isReadOnlyMode}
            onClick={() => addTelephone()}
            className={classes.addButton}
            variant="contained"
          >
            <AddCircleIcon />
          </IconButton>
          {telephoneList.map((value, index) => {
            return (
              <div key={index}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    disabled={isReadOnlyMode}
                    key={index}
                    required
                    id="standard-required"
                    name="telephone"
                    label="Telephone"
                    value={telephoneList[index]?.contactDetail}
                    onChange={event => handleTelephoneChange(index, event)}
                    margin="normal"
                    className={classes.width100}
                  />
                  {telephoneList.length > 0 && (
                    <IconButton
                      disabled={isReadOnlyMode}
                      onClick={() => removeTelephone(index)}
                      variant="contained"
                    >
                      <HighlightOffIcon
                        style={{ color: isReadOnlyMode ? '#FFBABC' : 'red' }}
                        fontSize="small"
                      />
                    </IconButton>
                  )}
                </div>
                {errorState?.telephoneList
                  ?.filter(errorObj => errorObj.errorIndex === index)
                  .map((obj, errorIndex) => (
                    <span
                      style={{ fontSize: '12px', color: 'red' }}
                      key={errorIndex}
                    >
                      {obj.errorMessage}
                    </span>
                  ))}
              </div>
            );
          })}
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <Grid
            container
            spacing={4}
            // className={classes.width100}
            md={12}
            xs={12}
            sm={12}
          >
            <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
              Social Media
              <IconButton
                disabled={isReadOnlyMode}
                onClick={() => addSM()}
                className={classes.addButton}
                variant="contained"
              >
                <AddCircleIcon />
              </IconButton>
            </Grid>
            {socialList.map((value, index) => {
              return (
                <>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    style={{ paddingRight: '0px' }}
                  >
                    <FormControl
                      key={index}
                      className={classes.width100}
                      style={{ marginTop: '16px' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Media Type
                      </InputLabel>
                      <Select
                        disabled={isReadOnlyMode}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={socialList[index].contactTypeID}
                        onChange={event => handleSocialChange(index, event)}
                        name="mediaType"
                        label="Media Type"
                      >
                        {[
                          ...filter(contactTypeList, function(o) {
                            return o.value != 5 && o.value != 6;
                          }),
                        ].map(contactType => (
                          <MenuItem
                            key={contactType.value}
                            value={contactType.value}
                            disabled={find(socialList, {
                              contactTypeID: contactType.value,
                            })}
                          >
                            {contactType.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errorState?.socialHandleList
                      ?.filter(errorObj => errorObj.errorIndex === index)
                      .map((obj, errorIndex) => (
                        <span
                          style={{ fontSize: '12px', color: 'red' }}
                          key={errorIndex}
                        >
                          {obj.errorMessage}
                        </span>
                      ))}
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    style={{ paddingRight: '0px' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        disabled={isReadOnlyMode}
                        id="standard-required"
                        name="id"
                        label="ID"
                        value={socialList[index].contactDetail}
                        onChange={event => handleSocialChange(index, event)}
                        margin="normal"
                        className={classes.width100}
                      />
                      {socialList.length > 0 && (
                        <IconButton
                          disabled={isReadOnlyMode}
                          onClick={() => removeSM(index)}
                          variant="contained"
                        >
                          <HighlightOffIcon
                            style={{
                              color: isReadOnlyMode ? '#FFBABC' : 'red',
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      )}
                    </div>
                    {errorState?.socialList
                      ?.filter(errorObj => errorObj.errorIndex === index)
                      .map((obj, errorIndex) => (
                        <span
                          style={{ fontSize: '12px', color: 'red' }}
                          key={errorIndex}
                        >
                          {obj.errorMessage}
                        </span>
                      ))}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Is the source company operating in an Industrial Park/Industrial
              Zone/Export Processing?
            </FormLabel>
            <RadioGroup
              disabled={isReadOnlyMode}
              aria-label="Gender"
              name="isSEZ"
              value={String(formData.isSEZ)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Is the source company ISO 9000 certified?
            </FormLabel>
            <RadioGroup
              disabled={isReadOnlyMode}
              aria-label="Gender"
              name="isISO9000"
              value={String(formData.isISO9000)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Is the source company HACCP certified?
            </FormLabel>
            <RadioGroup
              disabled={isReadOnlyMode}
              aria-label="Gender"
              name="isHACCP"
              value={String(formData.isHACCP)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="seImage"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend" style={{ marginBottom: '10px' }}>
              Image
            </FormLabel>
            <FilePicker
              isReadOnlyMode={isReadOnlyMode}
              onChange={onSEImageChange}
              imageSource={
                seImage?.sourceEntityLogoThumbnail[0]
                  ?.sourceEntityLogoThumbnailSource || null
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="seImage"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Source Company Representative
            </FormLabel>
            <Button
              // disabled={isReadOnlyMode}
              // onClick={handleSave}
              onClick={() => setSourceRepPopup(true)}
              className="btn-primary"
              variant="contained"
              style={{ width: 'max-content', marginTop: '10px' }}
            >
              {isEmpty(minSourceRepDetails)
                ? 'Add Representative details'
                : isReadOnlyMode
                ? 'Show Representative details'
                : 'Edit Representative details'}
            </Button>
          </FormControl>

          {errorState?.sourceEntityRepresentative ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.sourceEntityRepresentative}
            </span>
          ) : (
            ''
          )}
        </Grid>

        {!isEmpty(minSourceRepDetails) && (
          <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#00aec8' }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ color: '#fff', padding: '10px' }}
                    >
                      First Name
                    </TableCell>
                    <TableCell align="center" style={{ color: '#fff' }}>
                      Last Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: '#fff', padding: '10px' }}
                    >
                      Designation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={minSourceRepDetails.name}>
                    <TableCell align="center" style={{ padding: '10px' }}>
                      {minSourceRepDetails.firstName}
                    </TableCell>
                    <TableCell align="center">
                      {minSourceRepDetails.lastName}
                    </TableCell>
                    <TableCell align="center" style={{ padding: '10px' }}>
                      {minSourceRepDetails.designation}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {sourceRepPopup && (
          <StepTwoForm
            loading={loading}
            countryList={countryList}
            externalCampaignMeta={externalCampaignMeta}
            handleSnackbar={handleSnackbar}
            setSourceRepPopup={setSourceRepPopup}
            sourceRepPopup={sourceRepPopup}
            setMinSourceRepDetails={setMinSourceRepDetails}
            backdropCSS={backdropCSS}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}

        <Grid
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          item
          md={12}
          xs={12}
          sm={6}
        >
          <Button
            // onClick={handleSave}
            onClick={formValidate}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <div>
        <Backdrop
          className={backdropCSS}
          open={
            regionListLoader ||
            cityListLoader ||
            langLegalLoader ||
            contactTypeListLoader ||
            SourceEntityGeneralLoader
          }
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
}

export default StepOneForm;
