import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { create } from 'apisauce';
import HelpIcon from '@material-ui/icons/Help';
import Backdrop from '@material-ui/core/Backdrop';
import { find } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Constants from '../../../static/Constants';
import StepOneForm from './StepOneForm';
import StepThreeForm from './StepThreeForm';
import StepFourForm from './StepFourForm';
import StepFiveForm from './StepFiveForm';
import StepSevenForm from './StepSevenForm';
import StepEightForm from './StepEightForm';
import StepSixForm from './StepSixForm';
import CircularProgressBar from '../CircularProgress/CircularProgressBar';
import InfoIcon from '@material-ui/icons/Info';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { Mail } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    // marginTop: '15px',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    margin: '30px auto',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    backgroundColor: '#eee',
    padding: '15px',
    // borderTop: '5px solid #676cb0',
  },
  stepperText: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  floatingHelpButton: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    // zIndex: 99999,
  },
  underReviewComponent: {
    width: '100%',
    backgroundColor: '#00aec8',
    marginTop: '30px',
    // marginBottom: '10px',
    borderRadius: '10px',
    padding: '5px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thankYouData: {
    marginTop: '15px',
    marginBottom: '15px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const formStepInfo = [
  {
    id: 1,
    name: 'Source Company',
  },
  {
    id: 2,
    name: 'Investment Opportunity Profile Step 1',
  },
  {
    id: 3,
    name: 'Investment Opportunity Profile Step 2',
  },
  {
    id: 4,
    name: 'Investment Opportunity Profile Step 3',
  },
  {
    id: 5,
    name: 'Investment Opportunity Profile Step 4',
  },
  {
    id: 6,
    name: 'Investment Opportunity Profile Step 5',
  },
  {
    id: 7,
    name: 'Investment Opportunity Profile Step 6',
  },
];

const snackbarMessage = {
  success: 'Success!',
  error: 'Something went wrong, please try again later!',
  validationFail:
    'Please enter all required fields and provide correct details!',
};

function StepperForm({
  backdropCSS,
  externalCampaignMeta,
  isEditMode,
  setIsNewRecord,
  isNewRecord,
  isReadOnlyMode,
}) {
  let todayDate = new Date().toISOString().slice(0, 10);
  const [snackbarState, setSnackbarState] = useState({
    isOpen: false,
    mode: 'success',
    title: 'success',
  });
  const [step, setStep] = useState(1);
  const [countryListLoader, setCountryListLoader] = useState(false);
  const [IOPLoader, setIOPLoader] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [IOPFormData, setIOPFormData] = useState({
    projectID: externalCampaignMeta.projectID,
    campaignID: externalCampaignMeta.campaignID,
    sourceEntityID: externalCampaignMeta.sourceEntityID,
    projectTitle: '',
    projectOpportunityTypeID: '',
    projectOpportunitySummary: '',
    cityID: null,
    otherCity: '',
    regionID: null,
    otherRegion: '',
    countryID: '',
    projectStrengths: '',
    marketAvailability: '',
    materialInputAvailability: '',
    technologyAvailability: '',
    infrastructureFacilities: '',
    investmentResourcesAvailability: '',
    fiscalIncentivesAvailability: '',
    humanResourcesAvailability: '',
    companyAndProjectStrengths: '',
    estimatedTotalInvestment: 0,
    iopProfilerUserID: externalCampaignMeta.iopProfilerUserID,
    iosContactUserID: null,
    projectCode: externalCampaignMeta.projectCode,
    projectGivenName: '',
    isNonDisclosureAgreement: 'false',
    isConsentToShareIop: 0,
    isRequestToBeInformed: 0,
    isYouthEmpowerment: 0,
    isWomenEmpowerment: 0,
    projectPlannedDate: todayDate,
    projectVisitedDate: todayDate,
    currentUserID: externalCampaignMeta.currentUserID,
    currentRoleID: externalCampaignMeta.currentRoleID,
    currentProjectStatusID: externalCampaignMeta.currentProjectStatusID,
    totalInvestmentCost: 0,
    capitalRaised: 0,
    fundingGap: 0,
    longitude: 0,
    latitude: 0,
    address: '',
    otherProjectCollaborationType: '',
    otherProjectOpportunityScope: '',
    projectLogos: [],
    projectLogoThumbnails: [],
    projectDocumentDetailsList: [],
    projectCollaboration: [],
    projectSDG: [],
    projectDocument: [],
    projectSkill: [],
    projectSector: [],
    projectProduct: [],
    projectDevelopmentStage: [
      {
        developmentStageID: 1,
        projectDevelopmentStageID: 0,
        projectID: externalCampaignMeta.projectID,
      },
    ],
    projectOpportunityScopeDetails: [],
    projectEFSDType: [],
    categoryId: '',
    isComplete: false,
  });
  const [SDOpen, setSDOpen] = useState(false);

  const classes = useStyles();
  const totalSteps = formStepInfo.length;
  const percentagePerStep = 100 / totalSteps;

  const handleSpeedDialClose = () => {
    setSDOpen(false);
  };

  const handleSpeedDialOpen = () => {
    setSDOpen(true);
  };

  const handleScroll = (xCordinate, yCordinate) => {
    window.scrollTo({ top: xCordinate, behavior: 'smooth' });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  useEffect(() => {
    setCountryListLoader(true);

    apiSauceInstance
      .get(`${Constants.getCountryList}`)
      .then(({ data, status }) => {
        if (status === 200 || status === 201) {
          const countryListNew = data.map(country => {
            return {
              value: country.countryID,
              label: country.countryName,
            };
          });
          setCountryList(countryListNew);
        } else {
          //error do something
        }

        setCountryListLoader(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setCountryListLoader(false);
      });
  }, []);

  const getIOPProjectDetails = async projectId => {
    try {
      setIOPLoader(true);
      const IOPResponse = await apiSauceInstance.get(
        `${Constants.getIOP}/${projectId}`
      );
      setIOPLoader(false);
      if (IOPResponse?.status === 200 || IOPResponse?.status === 201) {
        return IOPResponse.data;
      } else {
        //error do something
      }
    } catch (error) {
      if (error) {
        //do something
      }
      setIOPLoader(false);
    }

    return null;
  };

  const getStepInfo = id => {
    return find(formStepInfo, { id: id });
  };

  const handleSnackbar = (mode, title) => {
    setSnackbarState({
      isOpen: true,
      mode,
      title: snackbarMessage[title],
    });
  };

  const handleIOPSave = (payload, nextStepIndex) => {
    handleScroll(0, 0);
    if (isReadOnlyMode) {
      setStep(nextStepIndex);
    } else {
      setIOPLoader(true);
      apiSauceInstance
        .put(`${Constants.putIOP}/${externalCampaignMeta?.projectID}`, payload)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            handleSnackbar('success', 'success');
            setStep(nextStepIndex);
          } else {
            handleSnackbar('error', 'error');
          }

          setIOPLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setIOPLoader(false);
          handleSnackbar('error', 'error');
        });
    }
  };

  return (
    <>
      <div className={classes.outerContainer}>
        <div className={classes.stepContainer}>
          {step <= totalSteps ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'end',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'inline-flex',
                }}
              >
                {/* <CircularProgress
                  size={80}
                  variant="determinate"
                  value={step * percentagePerStep}
                /> */}
                <CircularProgressBar value={step * percentagePerStep} />
                <Box className={classes.stepperText}>
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {step} of {totalSteps}
                  </Typography>
                </Box>
              </Box>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '50px',
                }}
              >
                <span style={{ fontSize: '18px', fontWeight: '800' }}>
                  {getStepInfo(step)?.name}
                </span>
                <span style={{ fontSize: '14px', fontWeight: '400' }}>
                  {getStepInfo(step + 1)?.name &&
                    `Next: ${getStepInfo(step + 1)?.name}`}
                </span>
              </div>
            </div>
          ) : (
            <div style={{ fontSize: '28px', fontWeight: '700' }}>
              Thank you!
            </div>
          )}
        </div>

        {isReadOnlyMode && step <= totalSteps && (
          <div className={classes.underReviewComponent}>
            <InfoIcon style={{ marginRight: '5px' }} />
            <span>
              This proposal is submitted successfully and is under review!
            </span>
          </div>
        )}

        {step === 1 && (
          <StepOneForm
            countryList={countryList}
            setStep={setStep}
            externalCampaignMeta={externalCampaignMeta}
            handleSnackbar={handleSnackbar}
            backdropCSS={backdropCSS}
            isEditMode={isEditMode}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            setIsNewRecord={setIsNewRecord}
            isNewRecord={isNewRecord}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}
        {/* {step === 2 && (
          <StepTwoForm
            setStep={setStep}
            countryList={countryList}
       
            sourceEntityIdentity={sourceEntityIdentity}
            handleSnackbar={handleSnackbar}
          />
        )} */}
        {step === 2 && (
          <StepThreeForm
            setStep={setStep}
            countryList={countryList}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            // generalsourceEntityID={generalsourceEntityID}
            handleSnackbar={handleSnackbar}
            getIOPProjectDetails={getIOPProjectDetails}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleIOPSave={handleIOPSave}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}
        {step === 3 && (
          <StepFourForm
            setStep={setStep}
            countryList={countryList}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            // generalsourceEntityID={generalsourceEntityID}
            handleSnackbar={handleSnackbar}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleIOPSave={handleIOPSave}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}
        {step === 4 && (
          <StepFiveForm
            setStep={setStep}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleSnackbar={handleSnackbar}
            // setStep={setStep}
            // countryList={countryList}
            // generalsourceEntityID={generalsourceEntityID}
            handleIOPSave={handleIOPSave}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}

        {step === 5 && (
          <StepSixForm
            setStep={setStep}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleIOPSave={handleIOPSave}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}

        {step === 6 && (
          <StepSevenForm
            setStep={setStep}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleIOPSave={handleIOPSave}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}

        {step === 7 && (
          <StepEightForm
            setStep={setStep}
            backdropCSS={backdropCSS}
            externalCampaignMeta={externalCampaignMeta}
            isEditMode={isEditMode}
            setIOPFormData={setIOPFormData}
            IOPFormData={IOPFormData}
            handleIOPSave={handleIOPSave}
            handleSnackbar={handleSnackbar}
            handleScroll={handleScroll}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}

        {step > totalSteps ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className={classes.thankYouData} style={{ marginTop: '30px' }}>
              We are pleased to confirm receipt of your project proposal for the
              World Without Hunger Conference Investment Forum.
            </div>
            <div className={classes.thankYouData}>
              Your submission is now under review. We will keep you informed of
              the status throughout the selection process. You can expect to
              hear from us regarding the outcome by late September. Should your
              project be selected, you will have the opportunity to present and
              pitch your innovative solutions to potential investors at the
              Investment Forum, which will take place during the World Without
              Hunger Conference in Addis Ababa, Ethiopia, in November 2024.
            </div>
            <div className={classes.thankYouData}>
              We appreciate your effort and interest in contributing to
              sustainable food security initiatives. Should you have any
              questions or require further information, please do not hesitate
              to contact us through&nbsp;
              <a href="mailto:investmentportal@unido.org">
                investmentportal@unido.org
              </a>
              .
            </div>
          </div>
        ) : (
          ''
        )}

        <SpeedDial
          ariaLabel="Help"
          className={classes.floatingHelpButton}
          icon={<HelpIcon />}
          onClose={handleSpeedDialClose}
          onOpen={handleSpeedDialOpen}
          open={SDOpen}
          direction={'up'}
        >
          <SpeedDialAction
            icon={<Mail />}
            tooltipTitle={`Contact us at: investmentportal@unido.org`}
            onClick={() => {
              window.open('mailto:investmentportal@unido.org');
              handleSpeedDialClose();
            }}
          />
        </SpeedDial>
      </div>
      <div>
        <Backdrop className={backdropCSS} open={countryListLoader || IOPLoader}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
      <Snackbar
        open={snackbarState.isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarState.mode}
          style={{ fontSize: '14px' }}
        >
          {snackbarState.title}
        </Alert>
      </Snackbar>
    </>
  );
}

export default StepperForm;
