import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Input,
  ListItemText,
} from '@material-ui/core';
import Constants from '../../../static/Constants';
import { create } from 'apisauce';
import { makeStyles } from '@material-ui/core/styles';
import { trimString, validateStepFourForm } from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '10px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

function StepFourForm({
  setStep,
  countryList,
  backdropCSS,
  externalCampaignMeta,
  selectedCountry,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  handleSnackbar,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const [errorState, setErrorState] = useState({});

  const [SDGList, setSDGList] = useState([
    { value: 'SDG 1', label: 'SDG 1' },
    { value: 'SDG 2', label: 'SDG 2' },
  ]);

  const [regionListLoader, setRegionListLoader] = useState(false);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [countryIDSelected, setCountryIDSelected] = useState('');
  const [regionList, setRegionList] = useState([]);
  const [regionIdSelected, setRegionIdSelected] = useState('');
  const [cityList, setCityList] = useState([]);
  const [SDGValues, setSDGValues] = useState([]);
  const [SDGListLoader, setSDGListLoader] = useState(false);

  useEffect(() => {
    setSDGListLoader(true);
    apiSauceInstance
      .get(`${Constants.getSDGCode}`)
      .then(({ data, status }) => {
        if (status === 200 || status === 201) {
          const SDGListNew = data.map(sdg => {
            return {
              value: sdg.sdgid,
              label: sdg.sdgTitle,
            };
          });
          setSDGList(SDGListNew);
        } else {
          //error do something
        }

        setSDGListLoader(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setSDGListLoader(false);
      });
  }, []);

  useEffect(() => {
    try {
      const sdgList = IOPFormData?.projectSDG.map(sdgInfo => {
        return sdgInfo.sdgid;
      });

      setSDGValues(sdgList);
    } catch (error) {
      if (error) {
        //do something
      }
    }
  }, []);

  useEffect(() => {
    if (countryIDSelected) {
      setRegionListLoader(true);
      apiSauceInstance
        .get(`${Constants.getRegionList}/${countryIDSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const regionListNew = data.map(region => {
              return {
                value: region.regionID,
                label: region.regionName,
              };
            });
            setRegionList(regionListNew);
          } else {
            //error do something
          }

          setRegionListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setRegionListLoader(false);
        });
    }
  }, [countryIDSelected]);

  useEffect(() => {
    if (regionIdSelected) {
      setCityListLoader(true);
      apiSauceInstance
        .get(`${Constants.getCityList}/${regionIdSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const cityListNew = data.map(city => {
              return {
                value: city.cityID,
                label: city.cityName,
              };
            });
            setCityList(cityListNew);
          } else {
            //error do something
          }

          setCityListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setCityListLoader(false);
        });
    }
  }, [regionIdSelected]);

  useEffect(() => {
    if (IOPFormData.countryID) {
      setCountryIDSelected(IOPFormData.countryID);
    } else {
      setCountryIDSelected(selectedCountry);
      setIOPFormData({
        ...IOPFormData,
        countryID: selectedCountry,
      });
    }
  }, [IOPFormData.countryID]);

  useEffect(() => {
    setRegionIdSelected(IOPFormData.regionID);
  }, [IOPFormData.regionID]);

  const handleTrim = e => {
    const { name, value } = e.target;
    if (
      name === 'projectOpportunitySummary' ||
      name == 'companyAndProjectStrengths'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    } else if (
      name === 'address' ||
      name == 'otherRegion' ||
      name == 'otherCity'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };
    delete errors[name];
    setErrorState(errors);

    if (name == 'countryID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
        regionID: '',
        otherRegion: '',
        cityID: '',
        otherCity: '',
      });
      return;
    }

    if (name == 'cityID') {
      delete errors.otherCity;
      setErrorState(errors);
      setIOPFormData({
        ...IOPFormData,
        otherCity: '',
      });
    }

    if (name == 'regionID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
        cityID: '',
        otherCity: '',
        otherRegion: '',
      });
      return;
    }

    if (name === 'projectSDG') {
      setSDGValues(value);
      const structuredSDGs = value.map(sdgValue => {
        return {
          projectSDGID: 0,
          projectID: externalCampaignMeta.projectID,
          sdgid: sdgValue,
          sdgOrder: sdgValue,
        };
      });
      setIOPFormData({
        ...IOPFormData,
        [name]: structuredSDGs,
      });
      return;
    } else if (
      name === 'projectOpportunitySummary' ||
      name == 'companyAndProjectStrengths'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    } else if (name == 'address') {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    }

    setIOPFormData({
      ...IOPFormData,
      [name]: value,
    });
  };

  const formValidateProject = () => {
    const errorObj = validateStepFourForm(IOPFormData);

    if (!(IOPFormData.regionID == 999)) {
      delete errorObj.otherRegion;
    }

    if (!(IOPFormData.cityID == 9999)) {
      delete errorObj.otherCity;
    }

    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      // handleSave();
      handleIOPSave(IOPFormData, 4);
    } else {
      handleScroll(0, 0);
      handleSnackbar('warning', 'validationFail');
    }
  };

  return (
    <div className="container" style={{ marginTop: '20px' }}>
      <Grid container spacing={4} className={classes.width100}>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Related SDGs
            </InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="projectSDG"
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={SDGValues}
              onChange={handleChange}
              input={<Input />}
              renderValue={selected => {
                const tempArr = [];
                selected.forEach(selectItem => {
                  const lanObj = SDGList.find(obj => obj.value == selectItem);
                  tempArr.push(lanObj?.label);
                });

                return tempArr.join(', ');
              }}
            >
              {SDGList?.map(sdg => (
                <MenuItem key={sdg.value} value={sdg.value}>
                  <Checkbox checked={SDGValues?.indexOf(sdg.value) > -1} />
                  <ListItemText primary={sdg.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="projectOpportunitySummary"
            label="Project Summary Description"
            value={IOPFormData.projectOpportunitySummary}
            onChange={handleChange}
            onBlur={handleTrim}
            defaultValue=""
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.projectOpportunitySummary.length}/2000
          </span> */}
        </Grid>

        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl className={classes.width100}>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                disabled={true && isReadOnlyMode}
                // disabled
                name="countryID"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={IOPFormData.countryID || countryIDSelected}
                onChange={handleChange}
              >
                {countryList.map(country => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.countryID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.countryID}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl
              className={classes.width100}
              disabled={countryIDSelected === ''}
            >
              <InputLabel
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
              >
                Region *
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="regionID"
                label="Region"
                labelId={
                  countryIDSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
                id={
                  countryIDSelected !== ''
                    ? 'demo-simple-select'
                    : 'demo-simple-select-disabled'
                }
                value={IOPFormData.regionID}
                onChange={handleChange}
              >
                {regionList.map(region => (
                  <MenuItem key={region.value} value={region.value}>
                    {region.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.regionID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.regionID}
              </span>
            ) : (
              ''
            )}

            {IOPFormData.regionID === 999 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherRegion"
                label="Enter Region Name"
                value={IOPFormData.otherRegion}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                className={classes.width100}
                inputProps={{ maxLength: 50 }}
              />
            ) : (
              ''
            )}
            {errorState?.otherRegion ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherRegion}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>
        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl
              className={classes.width100}
              disabled={regionIdSelected === ''}
            >
              <InputLabel
                id={
                  regionIdSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
              >
                City *
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="cityID"
                label="City"
                labelId={
                  regionIdSelected !== ''
                    ? 'demo-simple-select-label'
                    : 'demo-simple-select-disabled-label'
                }
                id={
                  regionIdSelected !== ''
                    ? 'demo-simple-select'
                    : 'demo-simple-select-disabled'
                }
                value={IOPFormData.cityID}
                onChange={handleChange}
              >
                {cityList.map(city => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.cityID ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.cityID}
              </span>
            ) : (
              ''
            )}

            {IOPFormData.cityID === 9999 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherCity"
                label="Enter City Name"
                value={IOPFormData.otherCity}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                className={classes.width100}
                inputProps={{ maxLength: 50 }}
              />
            ) : (
              ''
            )}
            {errorState?.otherCity ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherCity}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            id="standard-required"
            name="address"
            label="Address"
            value={IOPFormData.address}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 250 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.address.length}/250
          </span> */}
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="companyAndProjectStrengths"
            label="Project Strengths"
            value={IOPFormData.companyAndProjectStrengths}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.companyAndProjectStrengths.length}/2000
          </span> */}
        </Grid>

        <br />
        <Grid
          item
          xs={12}
          className={classes.btnContainer}
          style={{ paddingRight: '0px' }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              handleScroll(0, 0), setStep(prevState => prevState - 1);
            }}
            className="btn-primary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            onClick={formValidateProject}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <div>
        <Backdrop
          className={backdropCSS}
          open={regionListLoader || SDGListLoader || cityListLoader}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
}

export default StepFourForm;
