import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Input,
  ListItemText,
} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FilePicker from '../FilePicker/FilePicker';
import { convertBase64 } from '../../shared/base64Convertor';
import Constants from '../../../static/Constants';
import { create } from 'apisauce';
import { makeStyles } from '@material-ui/core/styles';
import { trimString, validateStepThreeForm } from './Helpers/DataValidators';
import {
  populateIOPFiveObject,
  populateIOPTabFourObject,
  populateIOPTabOneObject,
  populateIOPTabSixObject,
  populateIOPTabThreeObject,
  populateIOPTabTwoObject,
} from './Helpers/DataFormatters';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '10px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
  datePicker: {
    fontSize: '15px',
  },
}));

function StepThreeForm({
  setStep,
  backdropCSS,
  externalCampaignMeta,
  isEditMode,
  selectedCountry,
  getIOPProjectDetails,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  handleSnackbar,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const [errorState, setErrorState] = useState({});
  const [categoryListLoader, setCategoryListLoader] = useState(false);
  const [IOPStepOneLoader, setIOPStepOneLoader] = useState(false);
  const [seImage, setSEImage] = useState({
    projectLogos: [],
  });
  const [nonEquityValues, setNonEquityValues] = useState([]);
  const [
    projectOpportunityScopeDetailsValues,
    setProjectOpportunityScopeDetailsValues,
  ] = useState([]);
  const [otherCollaborationValues, setOtherCollaborationValues] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [equityArrangementList, setEquityArrangementList] = useState([]);
  const [projectScopeList, setProjectScopeList] = useState([]);
  const [collaborationList, setCollaborationList] = useState([]);
  const [nonEquityArrangementList, setNonEquityArrangementList] = useState([]);

  useEffect(async () => {
    setIOPStepOneLoader(true);
    try {
      const equityResponse = await apiSauceInstance.get(
        `${Constants.getEquityList}`
      );
      const scopeResponse = await apiSauceInstance.get(
        `${Constants.getScopeList}`
      );

      const collabEquityResponse = await apiSauceInstance.get(
        `${Constants.getCollabEquity}`
      );

      if (
        (equityResponse.status == 200 || equityResponse.status == 201) &&
        (scopeResponse.status === 200 || scopeResponse.status === 201) &&
        (collabEquityResponse.status === 200 ||
          collabEquityResponse.status === 201)
      ) {
        const equityData = equityResponse.data;
        const scopeData = scopeResponse.data;
        const collabEquityData = collabEquityResponse.data;

        const equityListNew = equityData.map(equity => ({
          value: equity.projectOpportunityTypeID,
          label: equity.projectOpportunityTypeName,
        }));

        const scopeListNew = scopeData.map(scope => ({
          value: scope.projectOpportunityScopeID,
          label: scope.projectOpportunityScopeName,
        }));

        collabEquityData.forEach(obj => {
          if (obj.projectCollaborationCategoryID === 1) {
            setNonEquityArrangementList(prevState => [
              ...prevState,
              {
                value: obj.projectCollaborationTypeID,
                label: obj.projectCollaborationTypeName,
              },
            ]);
          } else if (obj.projectCollaborationCategoryID === 2) {
            setCollaborationList(prevState => [
              ...prevState,
              {
                value: obj.projectCollaborationTypeID,
                label: obj.projectCollaborationTypeName,
              },
            ]);
          }
        });

        setEquityArrangementList(equityListNew);
        setProjectScopeList(scopeListNew);
      } else {
        //error do something
      }
      setIOPStepOneLoader(false);
    } catch (error) {
      setIOPStepOneLoader(false);
    }
  }, []);

  useEffect(() => {
    setIOPFormData({
      ...IOPFormData,
      ...seImage,
    });
  }, [seImage]);

  useEffect(() => {
    setCategoryListLoader(true);
    apiSauceInstance
      .get(`${Constants.getCategoryList}`)
      .then(({ data, status }) => {
        if (status === 200 || status === 201) {
          const categoryListNew = data.map(category => {
            return {
              value: category.iopCategoryID,
              label: category.iopCategoryName,
            };
          });
          setCategoryList(categoryListNew);
        } else {
          //error do something
        }

        setCategoryListLoader(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setCategoryListLoader(false);
      });
  }, []);

  useEffect(async () => {
    const projectID = externalCampaignMeta?.projectID;
    if (projectID !== '') {
      try {
        const IOPData = await getIOPProjectDetails(projectID);
        const formattedFormDataIOPOneGet = populateIOPTabOneObject(IOPData);

        if (formattedFormDataIOPOneGet.isError) {
          handleSnackbar('error', 'error');
          return;
        }

        const formDataIOPOneGet = formattedFormDataIOPOneGet.result;

        const scopeDetailList = formDataIOPOneGet.projectOpportunityScopeDetails.map(
          scopeInfo => {
            return scopeInfo.projectOpportunityScopeID;
          }
        );
        setProjectOpportunityScopeDetailsValues(scopeDetailList);

        const nonEquityList = formDataIOPOneGet.projectCollaboration
          .filter(collabInfo => {
            return collabInfo.projectCollaborationCategoryID == 1;
          })
          .map(nonEquityInfo => nonEquityInfo.projectCollaborationTypeID);
        setNonEquityValues(nonEquityList);

        const otherCollabList = formDataIOPOneGet.projectCollaboration
          .filter(collabInfo => {
            return collabInfo.projectCollaborationCategoryID == 2;
          })
          .map(otherCollabInfo => otherCollabInfo.projectCollaborationTypeID);
        setOtherCollaborationValues(otherCollabList);

        //iop step 2 fields
        const formattedFormDataIOPTwoGet = populateIOPTabTwoObject(IOPData);

        //iop step 3 fields
        const formattedFormDataIOPThreeGet = populateIOPTabThreeObject(IOPData);

        //iop step 4 fields
        const formattedFormDataIOPFourGet = populateIOPTabFourObject(IOPData);

        //iop step 5 fields
        const formattedFormDataIOPFiveGet = populateIOPFiveObject(IOPData);

        //iop step 6 fields
        const formattedFormDataIOPSixGet = populateIOPTabSixObject(IOPData);
        if (
          formattedFormDataIOPTwoGet.isError ||
          formattedFormDataIOPThreeGet.isError ||
          formattedFormDataIOPFourGet.isError ||
          formattedFormDataIOPFiveGet.isError ||
          formattedFormDataIOPSixGet.isError
        ) {
          handleSnackbar('error', 'error');
          return;
        }

        //iop step 2 fields
        const formDataIOPTwoGet = formattedFormDataIOPTwoGet.result;

        //iop step 3 fields
        const formDataIOPThreeGet = formattedFormDataIOPThreeGet.result;

        //iop step 4 fields
        const formDataIOPFourGet = formattedFormDataIOPFourGet.result;

        //iop step 5 fields
        const formDataIOPFiveGet = formattedFormDataIOPFiveGet.result;

        //iop step 6 fields
        const formDataIOPSixGet = formattedFormDataIOPSixGet.result;

        let todayDate = new Date().toISOString().slice(0, 10);
        let payload = {
          ...IOPFormData,
          ...formDataIOPOneGet,
          ...formDataIOPTwoGet,
          ...formDataIOPThreeGet,
          ...formDataIOPFourGet,
          ...formDataIOPFiveGet,
          ...formDataIOPSixGet,
          ...(IOPData?.countryID == null ? { countryID: selectedCountry } : {}),
          ...(IOPData?.projectVisitedDate == null
            ? { projectVisitedDate: todayDate }
            : {}),
          projectID: IOPData?.projectID,
          campaignID: IOPData?.campaignID,
          sourceEntityID: IOPData?.sourceEntityID,
          currentUserID: IOPData?.currentUserID,
          currentRoleID: IOPData?.currentRoleID,
          iopProfilerUserID: IOPData?.iopProfilerUserID,
          currentProjectStatusID: IOPData?.currentProjectStatusID,
          projectCode: IOPData?.projectCode,
        };

        setIOPFormData(payload);

        setSEImage({
          projectLogos: [
            {
              isPrimaryLogo: IOPData?.projectLogo?.[0]?.isPrimaryLogo,
              projectID: IOPData?.projectLogo?.[0]?.projectID,
              projectLogoSource: IOPData?.projectLogo?.[0]?.projectLogoSource,
            },
          ],
        });
      } catch (error) {
        if (error) {
          //do something
        }
      }
    }
  }, [externalCampaignMeta?.projectID]);

  const handleTrim = e => {
    const { name, value } = e.target;
    if (name === 'projectTitle' || name == 'projectGivenName') {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };
    delete errors[name];

    if (name == 'projectOpportunityScopeDetails') {
      delete errors.otherProjectOpportunityScope;
    }

    if (name == 'collaborationSought') {
      delete errors.otherProjectCollaborationType;
    }

    setErrorState(errors);

    if (name === 'nonEquityArrangements') {
      setNonEquityValues(value);
      const structuredNonEquityList = value.map(equityValue => {
        return {
          projectCollaborationCategoryID: 1,
          projectCollaborationID: 0,
          projectCollaborationTypeID: equityValue,
          projectID: externalCampaignMeta.projectID,
        };
      });
      const temp = IOPFormData.projectCollaboration.filter(
        obj => obj.projectCollaborationCategoryID === 2
      );
      const equityAndCollabList = [...temp, ...structuredNonEquityList];

      setIOPFormData({
        ...IOPFormData,
        projectCollaboration: equityAndCollabList,
      });

      return;
    } else if (name == 'projectPlannedDate') {
      // const updatedDate = new Date(value).toISOString();
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    } else if (name == 'projectVisitedDate') {
      // const updatedDate = new Date(value).toISOString();
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    } else if (name == 'projectOpportunityScopeDetails') {
      setProjectOpportunityScopeDetailsValues(value);
      const structuredProjectOpportunityScopeDetails = value.map(scopeValue => {
        return {
          projectOpportunityScopeDetailID: 0,
          projectID: externalCampaignMeta.projectID,
          projectOpportunityScopeID: scopeValue,
        };
      });
      setIOPFormData({
        ...IOPFormData,
        [name]: structuredProjectOpportunityScopeDetails,
      });
      return;
    } else if (name == 'collaborationSought') {
      setOtherCollaborationValues(value);
      const structuredCollaborationList = value.map(collabValue => {
        return {
          projectCollaborationCategoryID: 2,
          projectCollaborationID: 0,
          projectCollaborationTypeID: collabValue,
          projectID: externalCampaignMeta.projectID,
        };
      });

      const temp = IOPFormData.projectCollaboration.filter(
        obj => obj.projectCollaborationCategoryID === 1
      );

      const equityAndCollabListTwo = [...temp, ...structuredCollaborationList];

      setIOPFormData({
        ...IOPFormData,
        projectCollaboration: equityAndCollabListTwo,
      });
      return;
    } else if (name == 'isNonDisclosureAgreement') {
      let temp;
      if (value === 'true') {
        temp = true;
      } else {
        temp = false;
      }
      setIOPFormData({
        ...IOPFormData,
        [name]: temp,
      });
    } else if (name === 'projectTitle' || name == 'projectGivenName') {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    }

    setIOPFormData({
      ...IOPFormData,
      [name]: value,
    });
  };

  const formValidateIOP = () => {
    const errorObj = validateStepThreeForm(IOPFormData);

    if (!(projectOpportunityScopeDetailsValues?.indexOf(6) > -1)) {
      delete errorObj.otherProjectOpportunityScope;
    }

    if (!(otherCollaborationValues?.indexOf(11) > -1)) {
      delete errorObj.otherProjectCollaborationType;
    }

    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      let payload = {
        ...IOPFormData,
      };
      handleIOPSave(payload, 3);
    } else {
      handleScroll(0, 0);
      handleSnackbar('warning', 'validationFail');
    }
  };

  const onSEImageChange = async image => {
    const base64Conversion = image ? await convertBase64(image) : '';

    if (base64Conversion) {
      setSEImage({
        projectLogos: [
          {
            isPrimaryLogo: true,
            projectID: externalCampaignMeta?.projectID,
            projectLogoSource: base64Conversion,
          },
        ],
      });
    } else {
      setSEImage({
        projectLogos: [],
      });
    }
  };

  return (
    <div className="container" style={{ marginTop: '20px' }}>
      <Grid container spacing={4} className={classes.width100}>
        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <TextField
              required
              disabled={isReadOnlyMode}
              id="standard-required"
              name="projectTitle"
              label="Project Title"
              value={IOPFormData.projectTitle}
              onChange={handleChange}
              onBlur={handleTrim}
              // defaultValue="Hello World"
              className={classes.width100}
              inputProps={{ maxLength: 250 }}
            />
            {errorState?.projectTitle ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.projectTitle}
              </span>
            ) : (
              ''
            )}
            {/* <span style={{ fontSize: '10px' }}>
              {IOPFormData.projectTitle.length}/250
            </span> */}
          </div>
        </Grid>

        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl className={classes.width100}>
              <InputLabel id="demo-simple-select-label">Category *</InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="categoryId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={IOPFormData.categoryId}
                margin="normal"
                onChange={handleChange}
              >
                {categoryList?.map(category => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorState?.categoryId ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.categoryId}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        {/* <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <TextField
              required
              style={{ width: '100%' }}
              id="date"
              label="Planned Visit Date"
              name="projectPlannedDate"
              value={IOPFormData.projectPlannedDate}
              type="date"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.datePicker}
            />
            {errorState?.projectPlannedDate ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.projectPlannedDate}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid> */}

        {/* <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <TextField
              required
              style={{ width: '100%' }}
              id="date"
              label="Actual Visit Date"
              name="projectVisitedDate"
              type="date"
              value={IOPFormData.projectVisitedDate}
              // defaultValue="2017-05-24"
              // className={classes.textField}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.datePicker}
            />
            {errorState?.projectVisitedDate ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.projectVisitedDate}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid> */}

        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-simple-select-label">
              Equity Arrangements *
            </InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="projectOpportunityTypeID"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={IOPFormData.projectOpportunityTypeID}
              margin="normal"
              onChange={handleChange}
            >
              {equityArrangementList.map(equity => (
                <MenuItem key={equity.value} value={equity.value}>
                  {equity.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorState?.projectOpportunityTypeID ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.projectOpportunityTypeID}
            </span>
          ) : (
            ''
          )}
        </Grid>

        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Non Equity Arrangements
            </InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="nonEquityArrangements"
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={nonEquityValues}
              onChange={handleChange}
              input={<Input />}
              // renderValue={selected => selected.join(', ')}
              renderValue={selected => {
                const tempArr = [];
                selected.forEach(selectItem => {
                  const lanObj = nonEquityArrangementList.find(
                    obj => obj.value == selectItem
                  );
                  tempArr.push(lanObj?.label);
                });

                return tempArr.join(', ');
              }}
            >
              {nonEquityArrangementList?.map(nonEquity => (
                <MenuItem key={nonEquity.value} value={nonEquity.value}>
                  <Checkbox
                    checked={nonEquityValues?.indexOf(nonEquity.value) > -1}
                  />
                  <ListItemText primary={nonEquity.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl className={classes.width100}>
              <InputLabel id="demo-mutiple-checkbox-label">
                Project Scope
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="projectOpportunityScopeDetails"
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={projectOpportunityScopeDetailsValues}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => {
                  const tempArr = [];
                  selected.forEach(selectItem => {
                    const lanObj = projectScopeList.find(
                      obj => obj.value == selectItem
                    );
                    tempArr.push(lanObj?.label);
                  });

                  return tempArr.join(', ');
                }}
              >
                {projectScopeList?.map(projectScope => (
                  <MenuItem key={projectScope.value} value={projectScope.value}>
                    <Checkbox
                      checked={
                        projectOpportunityScopeDetailsValues?.indexOf(
                          projectScope.value
                        ) > -1
                      }
                    />
                    <ListItemText primary={projectScope.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {projectOpportunityScopeDetailsValues?.indexOf(6) > -1 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherProjectOpportunityScope"
                label="Please Specify Project Scope"
                value={IOPFormData.otherProjectOpportunityScope}
                onChange={handleChange}
                margin="normal"
                className={classes.width100}
              />
            ) : (
              ''
            )}
            {errorState?.otherProjectOpportunityScope ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherProjectOpportunityScope}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
          <div>
            <FormControl className={classes.width100}>
              <InputLabel id="demo-mutiple-checkbox-label">
                Other Collaboration Sought
              </InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="collaborationSought"
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={otherCollaborationValues}
                onChange={handleChange}
                input={<Input />}
                // renderValue={selected => selected.join(', ')}
                renderValue={selected => {
                  const tempArr = [];
                  selected.forEach(selectItem => {
                    const lanObj = collaborationList.find(
                      obj => obj.value == selectItem
                    );
                    tempArr.push(lanObj?.label);
                  });

                  return tempArr.join(', ');
                }}
              >
                {collaborationList?.map(otherCollab => (
                  <MenuItem key={otherCollab.value} value={otherCollab.value}>
                    <Checkbox
                      checked={
                        otherCollaborationValues?.indexOf(otherCollab.value) >
                        -1
                      }
                    />
                    <ListItemText primary={otherCollab.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {otherCollaborationValues?.indexOf(11) > -1 ? (
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="otherProjectCollaborationType"
                label="Please Specify Collaboration Sought"
                value={IOPFormData.otherProjectCollaborationType}
                onChange={handleChange}
                margin="normal"
                className={classes.width100}
              />
            ) : (
              ''
            )}
            {errorState?.otherProjectCollaborationType ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.otherProjectCollaborationType}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            id="standard-required"
            name="projectGivenName"
            label="Project Given Name"
            value={IOPFormData.projectGivenName}
            onChange={handleChange}
            onBlur={handleTrim}
            margin="normal"
            className={classes.width100}
            inputProps={{ maxLength: 250 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.projectGivenName.length}/250
          </span> */}
        </Grid>

        <Grid item md={6} xs={12} sm={9} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Non-disclosure agreement (NDA) requirement
            </FormLabel>

            <RadioGroup
              aria-label="Gender"
              name="isNonDisclosureAgreement"
              value={IOPFormData?.isNonDisclosureAgreement}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="seImage"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend" style={{ marginBottom: '10px' }}>
              Image
            </FormLabel>
            <FilePicker
              isReadOnlyMode={isReadOnlyMode}
              onChange={onSEImageChange}
              imageSource={seImage?.projectLogos[0]?.projectLogoSource || null}
            />
          </FormControl>
        </Grid>

        <br />
        <Grid
          item
          xs={12}
          className={classes.btnContainer}
          style={{ paddingRight: '0px' }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              handleScroll(0, 0), setStep(prevState => prevState - 1);
            }}
            className="btn-primary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            onClick={formValidateIOP}
            // onClick={handleSave}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <div>
        <Backdrop
          className={backdropCSS}
          open={categoryListLoader || IOPStepOneLoader}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
}

export default StepThreeForm;
