import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { find, isEmpty } from 'lodash';
import {
  trimString,
  validateFormProjectSector,
} from '../Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '7px',
  },
  width100: {
    width: '100%',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  removeButton: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

export default function ISICModal({
  companySectorCodeList,
  setFormDataCombined,
  formDataCombined,
  open,
  setOpen,
  selectedSector,
  setSelectedSector,
  externalCampaignMeta,
  handleSnackbar,
}) {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    companySectorCodeID: '',
    sectorDescription: '',
    isPrimary: formDataCombined.projectSector.length > 0 ? false : true,
  });

  useEffect(() => {
    if (selectedSector !== null) {
      const dataToEdit = formDataCombined?.projectSector?.[selectedSector];
      if (!isEmpty(dataToEdit)) {
        setFormData(dataToEdit);
      }
    }
  }, [selectedSector]);

  const [errorState, setErrorState] = useState({});

  // const handleCheck = indexToCheck => {
  //   const tempProjectSector = formDataCombined.projectSector.map(
  //     (projObj, index) => {
  //       if (index === indexToCheck) {
  //         return {
  //           ...projObj,
  //           isPrimary: !projObj.isPrimary,
  //         };
  //       } else {
  //         return {
  //           ...projObj,
  //           isPrimary: false,
  //         };
  //       }
  //     }
  //   );
  //   setProjectDocuments(tempTwoProjectDocuments);
  // };

  const handleTrim = e => {
    const { name, value } = e.target;
    if (name === 'sectorDescription') {
      setFormData({
        ...formData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };
    delete errors[name];
    setErrorState(errors);

    if (name == 'isPrimary') {
      setFormData({
        ...formData,
        [name]: e.target.checked,
      });
      return;
    } else if (name == 'sectorDescription') {
      setFormData({
        ...formData,
        [name]: value,
      });

      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onAutocompleteChange = (fieldName, value) => {
    let errors = { ...errorState };
    delete errors[fieldName];
    setErrorState(errors);

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const formValidateProjectSection = () => {
    const errorObj = validateFormProjectSector(formData);

    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      handleSave();
    } else {
      handleSnackbar('warning', 'validationFail');
    }
  };

  const handleSave = () => {
    let nonePrimary = true;
    let projectSectorTemp = [...formDataCombined.projectSector];
    if (formData.isPrimary == true) {
      projectSectorTemp = projectSectorTemp.map(proObj => {
        return {
          ...proObj,
          isPrimary: false,
        };
      });
    } else {
      projectSectorTemp.forEach((proObj, index) => {
        if (index !== selectedSector) {
          if (proObj.isPrimary == true) {
            nonePrimary = false;
          }
        }
      });

      if (nonePrimary == true) {
        projectSectorTemp = projectSectorTemp.map((proObj, index) => {
          if (index == 0) {
            return {
              ...proObj,
              isPrimary: true,
            };
          } else {
            return {
              ...proObj,
              isPrimary: false,
            };
          }
        });
      } else {
        projectSectorTemp = [...formDataCombined.projectSector];
      }
    }

    if (selectedSector != null) {
      projectSectorTemp[selectedSector] = {
        ...formData,
        isPrimary:
          selectedSector == 0 && nonePrimary == true
            ? true
            : formData.isPrimary,
        projectID: externalCampaignMeta.projectID,
        projectSectorID: 0,
      };
    } else {
      projectSectorTemp = [
        ...projectSectorTemp,
        {
          ...formData,
          projectID: externalCampaignMeta.projectID,
          projectSectorID: 0,
        },
      ];
    }

    setFormDataCombined(prevState => {
      return {
        ...prevState,
        projectSector: projectSectorTemp,
      };
    });
    setSelectedSector(null);
    setOpen(false);
  };

  const handleClose = () => {
    setSelectedSector(null);
    setOpen(false);
  };

  const body = (
    <Grid container spacing={1} className={classes.width100}>
      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <div>
          <FormControl className={classes.width100}>
            <Autocomplete
              options={companySectorCodeList}
              getOptionLabel={option =>
                `(${option.isicCode}) ${option.description}`
              }
              id="ISICCODE"
              value={find(companySectorCodeList, {
                companySectorCodeID: formData?.companySectorCodeID,
              })}
              onChange={(event, newValue) => {
                onAutocompleteChange(
                  'companySectorCodeID',
                  newValue?.companySectorCodeID
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Search by ISIC Code or Description *"
                  margin="normal"
                />
              )}
            />
          </FormControl>
          {errorState?.companySectorCodeID ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.companySectorCodeID}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>
      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <TextField
          id="standard-required"
          name="sectorDescription"
          label="Description"
          value={formData?.sectorDescription}
          onChange={handleChange}
          onBlur={handleTrim}
          className={classes.width100}
          inputProps={{ maxLength: 1990 }}
        />
        {/* <span style={{ fontSize: '10px' }}>
          {formData?.sectorDescription.length}/2000
        </span> */}
      </Grid>

      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData?.isPrimary}
                onChange={handleChange}
                name="isPrimary"
              />
            }
            label="Mark Sector as Primary"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">Project Sector</DialogTitle>
        <DialogContent dividers={'paper'}>{body}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.btnPrimary}
            variant="contained"
          >
            Cancel
          </Button>

          <Button
            // onClick={handleSave}
            onClick={formValidateProjectSection}
            className={classes.btnPrimary}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
