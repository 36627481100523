function validateSEGeneralForm(formData, emailList, telephoneList, socialList) {
  // Initialize an empty object to store errors
  let errors = {};

  // Validation for the "name" field
  if (formData.website !== '') {
    if (!/^[a-zA-Z][a-zA-Z.]*[a-zA-Z]+\.[a-zA-Z]+$/.test(formData.website)) {
      errors.website = 'Please enter a valid website URL';
    }
  }
  if (
    formData.postalCode == '' ||
    !/^[0-9]+[-]*[0-9]+[-]*[0-9]+$/.test(formData.postalCode)
  ) {
    errors.postalCode = 'Please enter a valid postal code.';
  }

  //   else {
  //     errors.website = '';
  //   }

  // Validate emailList array
  let emailErrors = [];
  emailList.forEach((emailObj, index) => {
    if (!validateEmail(emailObj.contactDetail)) {
      emailErrors.push({
        errorMessage: 'Invalid email format',
        errorIndex: index,
      });
    }
    // else {
    //   emailErrors.push({ errorMessage: '' });
    // }
  });
  if (emailErrors.length > 0) {
    errors.emailList = emailErrors;
  }

  // Validate telephoneList array
  let telephoneErrors = [];
  telephoneList.forEach((telephoneObj, index) => {
    if (!validateTelephone(telephoneObj.contactDetail)) {
      telephoneErrors.push({
        errorMessage: 'Invalid telephone number',
        errorIndex: index,
      });
    }
    // else {
    //   telephoneErrors.push({ errorMessage: '' });
    // }
  });
  if (telephoneErrors.length > 0) {
    errors.telephoneList = telephoneErrors;
  }

  // Validate social value array
  let socialErrors = [];
  socialList.forEach((socialObj, index) => {
    if (socialObj?.contactDetail == '') {
      socialErrors.push({
        errorMessage: `Invalid ID`,
        errorIndex: index,
      });
    }
    // else {
    //   socialErrors.push({ errorMessage: '' });
    // }
  });
  if (socialErrors.length > 0) {
    errors.socialList = socialErrors;
  }

  // Validate social handle array
  let socialHandleErrors = [];
  socialList.forEach((socialObj, index) => {
    if (socialObj?.contactTypeID == null || socialObj?.contactTypeID == '') {
      socialHandleErrors.push({
        errorMessage: `Invalid social media handle`,
        errorIndex: index,
      });
    }
    // else {
    //   socialErrors.push({ errorMessage: '' });
    // }
  });
  if (socialHandleErrors.length > 0) {
    errors.socialHandleList = socialHandleErrors;
  }

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (
      key == 'countryID' ||
      key == 'regionID' ||
      key == 'cityID' ||
      key == 'name' ||
      key == 'yearOfEstablishment' ||
      key == 'otherRegion' ||
      key == 'otherCity'
    ) {
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        // Update the error state with the corresponding error message
        if (key == 'countryID') {
          errors[key] = `Please select country`;
        } else if (key == 'regionID') {
          errors[key] = `Please select region`;
        } else if (key == 'otherRegion') {
          errors[key] = `Please enter region`;
        } else if (key == 'cityID') {
          errors[key] = `Please select City`;
        } else if (key == 'otherCity') {
          errors[key] = `Please enter City`;
        } else if (key == 'name') {
          errors[key] = `Please enter name`;
        } else if (key == 'yearOfEstablishment') {
          errors[key] = `Please enter Year of establishment`;
        }
      }

      if (
        formData['yearOfEstablishment'] == '' ||
        !/^\d+$/.test(formData['yearOfEstablishment']) ||
        isNaN(formData['yearOfEstablishment']) ||
        formData['yearOfEstablishment'].toString().length !== 4 ||
        formData['yearOfEstablishment'] < 1800 ||
        formData['yearOfEstablishment'] > new Date().getFullYear()
      ) {
        errors[
          'yearOfEstablishment'
        ] = `Please enter valid Year of establishment`;
      }
      //   else {
      //     // If the value is not empty, clear any existing error message
      //     errors[key] = '';
      //   }
    }
  }

  // Return the updated error state
  return errors;
}

function validateSERepresentativeForm(
  formData,
  emailList,
  telephoneList,
  socialList
) {
  // Initialize an empty object to store errors
  let errors = {};
  if (
    formData.postalCode == '' ||
    !/^[0-9]+[-]*[0-9]+[-]*[0-9]+$/.test(formData.postalCode)
  ) {
    errors.postalCode = 'Please enter a valid postal code.';
  }
  // else {
  //     errors.postalCode = '';
  // }
  // Validate emailList array
  let emailErrors = [];
  emailList.forEach((emailObj, index) => {
    if (!validateEmail(emailObj.contactDetail)) {
      emailErrors.push({
        errorMessage: 'Invalid email format',
        errorIndex: index,
      });
    }
    // else{
    //     emailErrors.push({ errorMessage: "" });
    // }
  });
  if (emailErrors.length > 0) {
    errors.emailList = emailErrors;
  }

  // Validate telephoneList array
  let telephoneErrors = [];
  telephoneList.forEach((telephoneObj, index) => {
    if (!validateTelephone(telephoneObj.contactDetail)) {
      telephoneErrors.push({
        errorMessage: 'Invalid telephone number',
        errorIndex: index,
      });
    }
    // else{
    //      telephoneErrors.push({ errorMessage: "" });
    // }
  });
  if (telephoneErrors.length > 0) {
    errors.telephoneList = telephoneErrors;
  }

  // Validate social array
  let socialErrors = [];
  socialList.forEach((socialObj, index) => {
    if (socialObj?.contactDetail == '') {
      socialErrors.push({
        errorMessage: `Invalid ${socialObj?.mediaTypeDescription} ID`,
        errorIndex: index,
      });
    }
    // else {
    //   socialErrors.push({ errorMessage: '' });
    // }
  });
  if (socialErrors.length > 0) {
    errors.socialList = socialErrors;
  }

  // Validate social handle array
  let socialHandleErrors = [];
  socialList.forEach((socialObj, index) => {
    if (socialObj?.contactTypeID == null || socialObj?.contactTypeID == '') {
      socialHandleErrors.push({
        errorMessage: `Invalid social media handle`,
        errorIndex: index,
      });
    }
    // else {
    //   socialErrors.push({ errorMessage: '' });
    // }
  });
  if (socialHandleErrors.length > 0) {
    errors.socialHandleList = socialHandleErrors;
  }

  for (const key in formData) {
    if (
      key == 'countryID' ||
      key == 'regionID' ||
      key == 'cityID' ||
      key == 'titleID' ||
      key == 'firstName' ||
      key == 'lastName' ||
      key == 'otherRegion' ||
      key == 'otherCity'
    ) {
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        // Update the error state with the corresponding error message
        if (key == 'countryID') {
          errors[key] = `Please select country`;
        } else if (key == 'regionID') {
          errors[key] = `Please select region`;
        } else if (key == 'otherRegion') {
          errors[key] = `Please enter region`;
        } else if (key == 'cityID') {
          errors[key] = `Please select City`;
        } else if (key == 'otherCity') {
          errors[key] = `Please enter City`;
        } else if (key == 'titleID') {
          errors[key] = `Please select title`;
        } else if (key == 'firstName') {
          errors[key] = `Please enter first name`;
        } else if (key == 'lastName') {
          errors[key] = `Please enter last name`;
        }
      }
      //   else {
      //     // If the value is not empty, clear any existing error message
      //     errors[key] = '';
      //   }
    }
  }

  // Return the updated error state
  return errors;
}

function validateStepThreeForm(formData) {
  // Initialize an empty object to store errors
  let errors = {};

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (
      key == 'categoryId' ||
      key == 'projectTitle' ||
      // key == 'projectPlannedDate' ||
      // key == 'projectVisitedDate' ||
      key == 'projectOpportunityTypeID' ||
      key == 'otherProjectOpportunityScope' ||
      key == 'otherProjectCollaborationType'
    ) {
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        // Update the error state with the corresponding error message

        if (key == 'categoryId') {
          errors[key] = `Please select category`;
        } else if (key == 'projectTitle') {
          errors[key] = `Please enter project title`;
        } else if (key == 'otherProjectOpportunityScope') {
          errors[key] = `Please enter project scope`;
        } else if (key == 'otherProjectCollaborationType') {
          errors[key] = `Please enter collaboration`;
        } else if (key == 'projectOpportunityTypeID') {
          errors[key] = `Please select equity arrangements`;
        }
      }
      //   else {
      //     // If the value is not empty, clear any existing error message
      //     errors[key] = '';
      //   }
    }
  }

  // Return the updated error state
  return errors;
}

function validateStepFourForm(formData) {
  // Initialize an empty object to store errors
  let errors = {};

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (
      key == 'countryID' ||
      key == 'regionID' ||
      key == 'cityID' ||
      key == 'otherRegion' ||
      key == 'otherCity'
    ) {
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        // Update the error state with the corresponding error message
        if (key == 'countryID') {
          errors[key] = `Please select country`;
        } else if (key == 'regionID') {
          errors[key] = `Please select region`;
        } else if (key == 'otherRegion') {
          errors[key] = `Please enter region`;
        } else if (key == 'cityID') {
          errors[key] = `Please select city`;
        } else if (key == 'otherCity') {
          errors[key] = `Please enter City`;
        }
      }
    }
  }

  // Return the updated error state
  return errors;
}

function validateFormProjectSector(formData) {
  // Initialize an empty object to store errors
  let errors = {};

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (key == 'companySectorCodeID') {
      if (formData[key] === undefined || formData[key] === '') {
        // Update the error state with the corresponding error message
        if (key == 'companySectorCodeID') {
          errors[key] = `Please select ISIC Code`;
        }
      }
    }
  }

  // Return the updated error state
  return errors;
}

function validateFormProductService(formData) {
  // Initialize an empty object to store errors
  let errors = {};

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (
      key == 'companyProductHSCodeID' ||
      key == 'companyProductUnitID' ||
      key == 'expectedNominalCapacity' ||
      key == 'expectedAnnualSales' ||
      key == 'percentExportShare'
    ) {
      if (formData[key] === undefined || formData[key] === '') {
        // Update the error state with the corresponding error message
        if (key == 'companyProductHSCodeID') {
          errors[key] = `Please select HSCode`;
        } else if (key == 'companyProductUnitID') {
          errors[key] = `Please select product unit`;
        } else if (key == 'expectedNominalCapacity') {
          errors[key] = `Please select capacity`;
        } else if (key == 'expectedAnnualSales') {
          errors[key] = `Please enter sales`;
        } else if (key == 'percentExportShare') {
          errors[key] = `Please enter share`;
        }
      }
    }

    if (
      (key == 'percentExportShare' && formData[key] > 100) ||
      formData[key] < 0
    ) {
      errors[key] = `Value must be between 0 & 100`;
    }

    // Return the updated error state
  }
  return errors;
}

function validateStepSevenForm(formData) {
  // Initialize an empty object to store errors
  let errors = {};

  // Iterate through other keys in the form state for empty or undefined values
  for (const key in formData) {
    if (key == 'capitalRaised') {
      if (
        Number(formData['totalInvestmentCost']) <
        Number(formData['capitalRaised'])
      ) {
        // Update the error state with the corresponding error message
        if (key == 'capitalRaised') {
          errors[key] = `Capital raised is invalid`;
        }
      }
    }
  }

  // Return the updated error state
  return errors;
}

// Function to validate email format
function validateEmail(email) {
  // Regular expression for basic email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Function to validate telephone number format
function validateTelephone(telephone) {
  // Regular expression for basic telephone number format validation
  const telephoneRegex = /^[+ 0-9 ]+[-]*[0-9 ]+[-]*[0-9 ]+[-]*[0-9 ]+$/;
  return telephoneRegex.test(telephone);
}

function trimString(str) {
  return str.replace(/^\s+/, '').replace(/\s+$/, '');
}

export {
  validateEmail,
  validateTelephone,
  trimString,
  validateSEGeneralForm,
  validateSERepresentativeForm,
  validateStepThreeForm,
  validateStepFourForm,
  validateFormProjectSector,
  validateFormProductService,
  validateStepSevenForm,
};
