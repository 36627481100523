import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ISICModal from './Extras/ISICModal';
import ProductServiceModal from './Extras/ProductServiceModal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { create } from 'apisauce';
import Constants from '../../../static/Constants';
import { find } from 'lodash';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  table: {
    minWidth: 650,
    marginBottom: '0px',
    padding: '10px',
  },
  tableHead: {
    fontSize: '15px',
    padding: '20px !important',
    color: '#fff',
  },
  removeButton: {
    color: 'red',
  },
  editButton: {
    color: '#2c3b8f',
  },
  addButton: {
    color: '#03AC13',
  },
  noDataComponent: {
    border: '1px dashed grey',
    padding: '20px 20px',
    width: '100%',
    textAlign: 'center',
  },
});

function StepFiveForm({
  setStep,
  externalCampaignMeta,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  backdropCSS,
  handleSnackbar,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const [formDataCombined, setFormDataCombined] = useState({
    projectSector: IOPFormData.projectSector,
    productsServices: IOPFormData.projectProduct,
  });
  const [openISIC, setOpenISIC] = useState(false);
  const [indexISIC, setIndexISIC] = useState(null);
  const [openProdSer, setOpenProdSer] = useState(false);
  const [indexProdSer, setIndexProdSer] = useState('');

  const [companySectorCodeList, setCompanySectorCodeList] = useState([]);
  const [companyProductHSCodeList, setCompanyProductHSCodeList] = useState([]);
  const [companyProductUnitList, setCompanyProductUnitList] = useState([]);

  const [ListLoader, setListLoader] = useState(false);

  const handleOpen = () => {
    setOpenISIC(true);
  };

  const handleOpenProServ = () => {
    setOpenProdSer(true);
  };

  const handleRemove = indexToRemove => {
    const tempProjectSectorData = formDataCombined.projectSector
      .slice(0, indexToRemove)
      .concat(formDataCombined.projectSector.slice(indexToRemove + 1));
    setFormDataCombined({
      ...formDataCombined,
      projectSector: tempProjectSectorData,
    });
  };

  const handleRemoveProdServ = indexToRemove => {
    const tempProdServData = formDataCombined.productsServices
      .slice(0, indexToRemove)
      .concat(formDataCombined.productsServices.slice(indexToRemove + 1));
    setFormDataCombined({
      ...formDataCombined,
      productsServices: tempProdServData,
    });
  };

  useEffect(async () => {
    try {
      setListLoader(true);
      const companySectorCode = await apiSauceInstance.get(
        `${Constants.getCompanySectorCode}/en`
      );
      const companyProductHSCode = await apiSauceInstance.get(
        `${Constants.getCompanyProductHSCode}/en`
      );
      const companyProductUnit = await apiSauceInstance.get(
        `${Constants.getCompanyProductUnit}`
      );

      if (
        (companySectorCode.status === 200 ||
          companySectorCode.status === 201) &&
        (companyProductHSCode.status === 200 ||
          companyProductHSCode.status === 201) &&
        (companyProductUnit.status === 200 || companyProductUnit.status === 201)
      ) {
        setCompanySectorCodeList(companySectorCode.data);
        setCompanyProductHSCodeList(companyProductHSCode.data);
        setCompanyProductUnitList(companyProductUnit.data);
      }
      setListLoader(false);
    } catch (error) {
      setListLoader(false);
    }
  }, []);

  useEffect(() => {
    if (
      formDataCombined.projectSector[0]?.isPrimary !== true &&
      formDataCombined.projectSector.length > 0
    ) {
      let projectSectorTemp = [...formDataCombined.projectSector];
      let nonePrimary = true;
      projectSectorTemp.forEach((proObj, index) => {
        if (proObj.isPrimary == true) {
          nonePrimary = false;
        }
      });

      if (nonePrimary == true) {
        projectSectorTemp = projectSectorTemp.map((proObj, index) => {
          if (index == 0) {
            return {
              ...proObj,
              isPrimary: true,
            };
          } else {
            return {
              ...proObj,
              isPrimary: false,
            };
          }
        });
        setFormDataCombined(prevState => {
          return {
            ...prevState,
            projectSector: projectSectorTemp,
          };
        });
      }
    }
  }, [formDataCombined]);

  const tableOneData = formDataCombined.projectSector.map((project, index) => {
    return {
      isPrimary: project?.isPrimary,
      ISICCode: project?.companySectorCodeID,
      editButton: (
        <IconButton
          disabled={isReadOnlyMode}
          onClick={() => {
            handleOpen(), setIndexISIC(index);
          }}
          className={classes.editButton}
          variant="contained"
        >
          <EditIcon />
        </IconButton>
      ),
      deleteButton: (
        <IconButton
          disabled={isReadOnlyMode}
          onClick={() => handleRemove(index)}
          className={classes.removeButton}
          variant="contained"
        >
          <CloseIcon />
        </IconButton>
      ),
    };
  });

  const tableTwoData = formDataCombined.productsServices.map(
    (product, index) => {
      return {
        HSCode: product.companyProductHSCodeID,
        productUnit: product.companyProductUnitID,
        expectedNominalCapacity: product.expectedNominalCapacity,
        expectedAnnualSales: product.expectedAnnualSales,
        exportShare: product.percentExportShare,
        editButton: (
          <IconButton
            disabled={isReadOnlyMode}
            onClick={() => {
              handleOpenProServ(), setIndexProdSer(index);
            }}
            className={classes.editButton}
            variant="contained"
          >
            <EditIcon />
          </IconButton>
        ),
        deleteButton: (
          <IconButton
            disabled={isReadOnlyMode}
            onClick={() => handleRemoveProdServ(index)}
            className={classes.removeButton}
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        ),
      };
    }
  );

  useEffect(() => {
    setIOPFormData({
      ...IOPFormData,
      projectSector: formDataCombined.projectSector,
      projectProduct: formDataCombined.productsServices,
    });
  }, [formDataCombined]);

  return (
    <div className="container" style={{ marginTop: '30px' }}>
      <div>
        <span>Project Sector</span>
        <IconButton
          disabled={isReadOnlyMode}
          onClick={() => {
            setOpenISIC(true);
          }}
          className={classes.addButton}
          variant="contained"
        >
          <AddCircleIcon />
        </IconButton>
      </div>
      {formDataCombined.projectSector.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: '#00aec8' }}>
              <TableRow>
                <TableCell align="center" className={classes.tableHead}>
                  ISIC
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableOneData.map(row => {
                const isicDetails = find(companySectorCodeList, {
                  companySectorCodeID: row.ISICCode,
                });

                return (
                  <TableRow key={row.name}>
                    <TableCell align="center">
                      {row.isPrimary ? (
                        <IconButton
                          className={classes.addButton}
                          variant="contained"
                        >
                          <CheckIcon />
                        </IconButton>
                      ) : (
                        ''
                      )}
                      ({isicDetails?.isicCode}) {isicDetails?.description}
                    </TableCell>
                    <TableCell align="center">
                      {row.editButton}
                      {row.deleteButton}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.noDataComponent}>
          No Sector, click on &quot;+&quot; button to create
        </div>
      )}
      <div style={{ marginTop: '15px' }}>
        <span>Products or services resulting from project</span>
        <IconButton
          disabled={isReadOnlyMode}
          onClick={() => {
            setOpenProdSer(true),
              setIndexProdSer(formDataCombined.productsServices.length);
          }}
          className={classes.addButton}
          variant="contained"
        >
          <AddCircleIcon />
        </IconButton>
      </div>
      {formDataCombined.productsServices.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: '#00aec8' }}>
              <TableRow>
                <TableCell align="center" className={classes.tableHead}>
                  Products to be produced (after project implementation)
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Product Unit
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Expected Nominal capacity (Installed)
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Expected Annual sales (USD) (After investment)
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Export Share (%)
                </TableCell>
                <TableCell align="center" className={classes.tableHead}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableTwoData.map(row => {
                const hsCodeDetails = find(companyProductHSCodeList, {
                  companyProductHSCodeID: row.HSCode,
                });
                const productUnitDetails = find(companyProductUnitList, {
                  companyProductUnitID: row.productUnit,
                });

                return (
                  <TableRow key={row.name}>
                    <TableCell align="center">
                      ({hsCodeDetails?.hsCode}) {hsCodeDetails?.description}
                    </TableCell>

                    <TableCell align="center">
                      ({productUnitDetails?.companyProductUnitDescription}
                      )&nbsp;
                      {productUnitDetails?.companyProductUnitCode}
                    </TableCell>

                    <TableCell align="center">
                      {row.expectedNominalCapacity}
                    </TableCell>

                    <TableCell align="center">
                      {row.expectedAnnualSales}
                    </TableCell>

                    <TableCell align="center">{row.exportShare}</TableCell>

                    <TableCell align="center">
                      {row.editButton}
                      {row.deleteButton}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.noDataComponent}>
          No Product, click on &quot;+&quot; button to create
        </div>
      )}
      <Grid
        item
        xs={12}
        className={classes.btnContainer}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
        }}
      >
        <Button
          style={{ marginRight: '10px' }}
          onClick={() => {
            handleScroll(0, 0), setStep(prevState => prevState - 1);
          }}
          className="btn-primary"
          variant="contained"
        >
          Back
        </Button>

        <Button
          onClick={() => handleIOPSave(IOPFormData, 5)}
          className="btn-primary"
          variant="contained"
        >
          {isReadOnlyMode ? 'Next' : 'Save'}
        </Button>
      </Grid>
      {openISIC && (
        <ISICModal
          companySectorCodeList={companySectorCodeList}
          setFormDataCombined={setFormDataCombined}
          formDataCombined={formDataCombined}
          open={openISIC}
          setOpen={setOpenISIC}
          selectedSector={indexISIC}
          setSelectedSector={setIndexISIC}
          externalCampaignMeta={externalCampaignMeta}
          handleSnackbar={handleSnackbar}
        />
      )}

      {openProdSer && (
        <ProductServiceModal
          companyProductHSCodeList={companyProductHSCodeList}
          companyProductUnitList={companyProductUnitList}
          setFormDataCombined={setFormDataCombined}
          formDataCombined={formDataCombined}
          open={openProdSer}
          setOpen={setOpenProdSer}
          selectedProduct={indexProdSer}
          setSelectedProduct={setIndexProdSer}
          externalCampaignMeta={externalCampaignMeta}
          handleSnackbar={handleSnackbar}
        />
      )}
      <div>
        <Backdrop className={backdropCSS} open={ListLoader}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
}

export default StepFiveForm;
