import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { find, isEmpty } from 'lodash';
import {
  trimString,
  validateFormProductService,
} from '../Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '7px',
  },
  width100: {
    width: '100%',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  removeButton: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

export default function ProductServiceModal({
  companyProductHSCodeList,
  companyProductUnitList,
  setFormDataCombined,
  formDataCombined,
  open,
  setOpen,
  selectedProduct,
  setSelectedProduct,
  externalCampaignMeta,
  handleSnackbar,
}) {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    companyProductHSCodeID: '',
    productDescription: '',
    companyProductUnitID: '',
    expectedNominalCapacity: '',
    expectedAnnualSales: '',
    percentExportShare: 0,
    percentLocalShare: 100,
  });
  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    if (selectedProduct !== null) {
      const dataToEdit = formDataCombined?.productsServices?.[selectedProduct];
      if (!isEmpty(dataToEdit)) {
        setFormData(dataToEdit);
      }
    }
  }, [selectedProduct]);

  const handleTrim = e => {
    const { name, value } = e.target;

    if (name == 'productDescription') {
      let localFormData = { ...formData };
      setFormData({
        ...localFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };
    delete errors[name];
    setErrorState(errors);

    let localFormData = { ...formData };
    if (name === 'percentExportShare') {
      localFormData = { ...localFormData, percentLocalShare: 100 - value };
      let cleanValue = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
      // Check if the value consists only of zeros
      if (/^0+$/.test(cleanValue)) {
        cleanValue = '0'; // Set it to '0' if it consists only of zeros
      } else {
        // Remove leading zeros if other digits are present

        cleanValue = cleanValue.replace(/^0+/, '');
      }

      setFormData({
        ...localFormData,
        [name]: parseInt(value) < 0 ? 0 : cleanValue,
      });
      return;
    } else if (name == 'productDescription') {
      setFormData({
        ...localFormData,
        [name]: value,
      });

      return;
    } else if (
      name == 'expectedNominalCapacity' ||
      name == 'expectedAnnualSales'
    ) {
      let cleanValue = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

      // Check if the value consists only of zeros

      if (/^0+$/.test(cleanValue)) {
        cleanValue = '0'; // Set it to '0' if it consists only of zeros
      } else {
        // Remove leading zeros if other digits are present

        cleanValue = cleanValue.replace(/^0+/, '');
      }
      setFormData({
        ...localFormData,
        [name]: parseInt(value) < 0 ? 0 : cleanValue,
      });
      return;
    }

    setFormData({
      ...localFormData,
      [name]: value,
    });
  };

  const onAutocompleteChange = (fieldName, value) => {
    let errors = { ...errorState };
    delete errors[fieldName];
    setErrorState(errors);

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const formValidateProductService = () => {
    const errorObj = validateFormProductService(formData);
    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      handleSave();
    } else {
      handleSnackbar('warning', 'validationFail');
    }
  };

  const handleSave = () => {
    let productsServices = [...formDataCombined.productsServices];

    if (selectedProduct != null) {
      productsServices[selectedProduct] = {
        ...formData,
        projectID: externalCampaignMeta.projectID,
        projectProductID: 0,
      };
    } else {
      productsServices = [
        ...formDataCombined.productsServices,
        {
          ...formData,
          projectID: externalCampaignMeta.projectID,
          projectProductID: 0,
        },
      ];
    }

    setFormDataCombined(prevState => {
      return {
        ...prevState,
        productsServices: productsServices,
      };
    });
    setSelectedProduct(null);
    setOpen(false);
  };

  const handleClose = () => {
    setSelectedProduct(null);
    setOpen(false);
  };

  const body = (
    <Grid container spacing={1} className={classes.width100}>
      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <div>
          <FormControl
            className={classes.width100}
            style={{ marginTop: '16px' }}
          >
            <Autocomplete
              options={companyProductHSCodeList}
              getOptionLabel={option =>
                `(${option.hsCode}) ${option.description}`
              }
              id="companyProductHSCodeID"
              name="companyProductHSCodeID"
              value={find(companyProductHSCodeList, {
                companyProductHSCodeID: formData?.companyProductHSCodeID,
              })}
              onChange={(event, newValue) => {
                onAutocompleteChange(
                  'companyProductHSCodeID',
                  newValue?.companyProductHSCodeID
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Search by HC Code or Description *"
                  margin="normal"
                />
              )}
            />
          </FormControl>
          {errorState?.companyProductHSCodeID ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.companyProductHSCodeID}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>
      <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
        <TextField
          id="standard-required"
          name="productDescription"
          label="Product Description"
          value={formData?.productDescription}
          onChange={handleChange}
          onBlur={handleTrim}
          className={classes.width100}
          inputProps={{ maxLength: 1990 }}
        />
        {/* <span style={{ fontSize: '10px' }}>
          {formData?.productDescription.length}/2000
        </span> */}
      </Grid>

      <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
        <div>
          <FormControl className={classes.width100}>
            <Autocomplete
              options={companyProductUnitList}
              getOptionLabel={option =>
                `(${option.companyProductUnitDescription}) ${option.companyProductUnitCode}`
              }
              id="companyProductUnitID"
              name="companyProductUnitID"
              value={find(companyProductUnitList, {
                companyProductUnitID: formData?.companyProductUnitID,
              })}
              onChange={(event, newValue) => {
                onAutocompleteChange(
                  'companyProductUnitID',
                  newValue?.companyProductUnitID
                );
              }}
              margin="normal"
              renderInput={params => (
                <TextField {...params} label="Product Unit *" margin="normal" />
              )}
            />
          </FormControl>
          {errorState?.companyProductUnitID ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.companyProductUnitID}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>

      <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
        <div>
          <TextField
            required
            id="standard-required"
            name="expectedNominalCapacity"
            label="Expected Nominal Capacity"
            value={formData?.expectedNominalCapacity}
            onChange={handleChange}
            className={classes.width100}
            style={{ marginTop: '16px' }}
          />
          {errorState?.expectedNominalCapacity ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.expectedNominalCapacity}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>

      <Grid item md={4} xs={12} sm={4}>
        <div>
          <TextField
            required
            id="standard-required"
            name="expectedAnnualSales"
            label="Expected Annual Sales"
            value={formData?.expectedAnnualSales}
            onChange={handleChange}
            className={classes.width100}
          />
          {errorState?.expectedAnnualSales ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.expectedAnnualSales}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>

      <Grid item md={4} xs={12} sm={4}>
        <div>
          <TextField
            required
            id="standard-required"
            name="percentExportShare"
            label="Export Share (%)"
            value={formData?.percentExportShare}
            onChange={handleChange}
            className={classes.width100}
          />
          {errorState?.percentExportShare ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.percentExportShare}
            </span>
          ) : (
            ''
          )}
        </div>
      </Grid>

      <Grid item md={4} xs={12} sm={4}>
        <div>
          <TextField
            type="number"
            required
            disabled={true}
            id="standard-required"
            name="percentLocalShare"
            label="Local Share (%)"
            value={formData?.percentLocalShare}
            className={classes.width100}
          />
          {/* {errorState?.percentLocalShare ? (
            <span style={{ fontSize: '12px', color: 'red' }}>
              {errorState.percentLocalShare}
            </span>
          ) : (
            ''
          )} */}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">
          Products or services resulting from project
        </DialogTitle>
        <DialogContent dividers={'paper'}>{body}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.btnPrimary}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            // onClick={handleSave}
            onClick={formValidateProductService}
            className={classes.btnPrimary}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
