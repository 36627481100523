/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Constants from '../../../static/Constants';
import { create } from 'apisauce';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MultiFilePicker from '../FilePicker/MultiFilePicker';
import { convertBase64 } from '../../shared/base64Convertor';
import { find } from 'lodash';
import { trimString, validateStepSevenForm } from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '10px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
  table: {
    // minWidth: 650,
    marginBottom: '0px',
    padding: '10px',
  },
  tableHead: {
    fontSize: '15px',
    padding: '20px !important',
    color: '#fff',
  },
  removeButton: {
    color: 'red',
  },
  editButton: {
    color: '#2c3b8f',
  },
  developmentStageComponent: {
    boxShadow:
      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    padding: '15px',
    borderRadius: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  developmentStageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '225px',
    margin: '15px',
    marginTop: '20px',
    cursor: 'pointer',
  },

  // new code
  developmentStageBlockDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  // new code

  developmentStageCircle: {
    border: '1px solid #2c3b8f',
    width: '35px',
    height: '35px',
    '-moz-border-radius': '50px',
    '-webkit-border-radius': '50px',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '5px',
    marginBottom: '10px',
    color: '#2c3b8f',
  },
  developmentStageCircleSelected: {
    border: '1px solid #2c3b8f',
    width: '35px',
    height: '35px',
    '-moz-border-radius': '50px',
    '-webkit-border-radius': '50px',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '5px',
    marginBottom: '10px',
    backgroundColor: '#2c3b8f',
    color: '#fff',
  },
  developmentStageLabel: {
    textAlign: 'center',
    color: '#2c3b8f',
  },
  developmentStageLabelSelected: {
    textAlign: 'center',
    color: '#2c3b8f',
    fontWeight: '500',
  },
}));

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '-webkit-fill-available',
    margin: '10px 40px 20px 40px',
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2c3b8f',
  },
}))(LinearProgress);

const developmentStages = [
  {
    value: 1,
    label: 'Preliminary',
  },
  {
    value: 2,
    label: 'Initial studies completed',
  },
  {
    value: 3,
    label: 'Feasibility study completed',
  },
  {
    value: 4,
    label: 'Approval required and obtained',
  },
];

const availableDocuments = [
  {
    value: 1,
    label: 'Financial reports',
  },
  {
    value: 2,
    label: 'Business plan, including financial projections',
  },
  {
    value: 3,
    label: 'Project feasibility study',
  },
  {
    value: 4,
    label: 'Contract award',
  },
];

function StepEightForm({
  setStep,
  externalCampaignMeta,
  isEditMode,
  backdropCSS,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  handleSnackbar,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const [errorState, setErrorState] = useState({});
  const [developmentStagesValue, setDevelopmentStagesValue] = useState('1');
  const [projectDocuments, setProjectDocuments] = useState([]);
  const [availableDocumentValues, setAvailableDocumentValues] = useState([]);
  const [EFSDListLoader, setEFSDListLoader] = useState(false);
  const [EFSDList, setEFSDList] = useState([]);
  const [EFSDValues, setEFSDValues] = useState([]);

  const getMaxDevelopmentStageID = arrayOfObjects => {
    if (arrayOfObjects.length === 0) {
      return null;
    }
    let maxDevelopmentStageID = arrayOfObjects[0].developmentStageID;
    for (let i = 1; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].developmentStageID > maxDevelopmentStageID) {
        maxDevelopmentStageID = arrayOfObjects[i].developmentStageID;
      }
    }
    return maxDevelopmentStageID;
  };

  useEffect(() => {
    try {
      if (IOPFormData?.projectDevelopmentStage.length !== 0) {
        const devStageID = getMaxDevelopmentStageID(
          IOPFormData?.projectDevelopmentStage
        );

        setDevelopmentStagesValue(devStageID);
      }

      if (IOPFormData?.projectDocument.length !== 0) {
        const availableDocsValues = IOPFormData?.projectDocument.map(docs => {
          return docs.documentTypeID;
        });
        setAvailableDocumentValues(availableDocsValues);
      }

      if (IOPFormData?.projectEFSDType.length !== 0) {
        const EFSDValues = IOPFormData?.projectEFSDType.map(efsd => {
          return efsd.efsdTypeID;
        });
        setEFSDValues(EFSDValues);
      }

      if (IOPFormData?.projectDocumentDetails.length !== 0) {
        setProjectDocuments(IOPFormData?.projectDocumentDetails);
        delete IOPFormData.projectDocumentDetails;
      } else {
        setProjectDocuments(IOPFormData?.projectDocumentDetailsList);
      }
    } catch (error) {
      if (error) {
        //do something
      }
    }
  }, []);

  useEffect(() => {
    setEFSDListLoader(true);
    apiSauceInstance
      .get(`${Constants.getEFSDType}`)
      .then(({ data, status }) => {
        if (status === 200 || status === 201) {
          const EFSDListNew = data.map(efsd => {
            return {
              value: efsd.efsdTypeID,
              label: efsd.efsdTypeName,
            };
          });
          setEFSDList(EFSDListNew);
        } else {
          //error do something
        }

        setEFSDListLoader(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setEFSDListLoader(false);
      });
  }, []);

  const handleTrim = e => {
    const { name, value } = e.target;
    if (name == 'fiscalIncentivesAvailability') {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };
    delete errors[name];
    setErrorState(errors);

    if (name === 'projectDevelopmentStage') {
      setDevelopmentStagesValue(value);
      const devArr = [];
      for (let i = 1; i <= value; i++) {
        devArr.push({
          developmentStageID: i,
          projectDevelopmentStageID: 0,
          projectID: externalCampaignMeta.projectID,
        });
      }

      setIOPFormData({
        ...IOPFormData,
        projectDevelopmentStage: devArr,
      });
      return;
    } else if (name === 'capitalRaised' || name === 'totalInvestmentCost') {
      let cleanValue = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

      // Check if the value consists only of zeros
      if (/^0+$/.test(cleanValue)) {
        cleanValue = '0'; // Set it to '0' if it consists only of zeros
      } else {
        // Remove leading zeros if other digits are present
        cleanValue = cleanValue.replace(/^0+/, '');
      }
      setIOPFormData({
        ...IOPFormData,
        [name]: parseInt(value) < 0 ? 0 : cleanValue,
      });

      return;
    } else if (name === 'projectDocument') {
      setAvailableDocumentValues(value);
      const structuredAvailableDocuments = value.map(docsID => {
        return {
          documentTypeID: docsID,
          projectDocumentID: 0,
          projectID: externalCampaignMeta.projectID,
        };
      });
      setIOPFormData({
        ...IOPFormData,
        [name]: structuredAvailableDocuments,
      });
      return;
    } else if (
      name == 'isConsentToShareIop' ||
      name == 'isRequestToBeInformed' ||
      name == 'isWomenEmpowerment' ||
      name == 'isYouthEmpowerment'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: Number(value),
      });
      return;
    } else if (name === 'projectEFSDType') {
      setEFSDValues(value);
      const structuredEFSDType = value.map(efsd => {
        return {
          projectEFSID: 0,
          efsdTypeID: efsd,
          projectID: externalCampaignMeta.projectID,
        };
      });

      setIOPFormData({
        ...IOPFormData,
        [name]: structuredEFSDType,
      });
      return;
    } else if (name === 'fiscalIncentivesAvailability') {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });
      return;
    } else if (name == 'isComplete') {
      setIOPFormData({
        ...IOPFormData,
        [name]: e.target.checked,
      });
      return;
    }
    setIOPFormData({
      ...IOPFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      IOPFormData.totalInvestmentCost !== 0 &&
      IOPFormData.capitalRaised !== 0
    ) {
      setIOPFormData({
        ...IOPFormData,
        fundingGap: makePositive(
          IOPFormData.totalInvestmentCost - IOPFormData.capitalRaised
        ),
      });
    }
  }, [IOPFormData.capitalRaised, IOPFormData.totalInvestmentCost]);

  const formValidateProject = () => {
    const errorObj = validateStepSevenForm(IOPFormData);

    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      handleIOPSave(IOPFormData, 8);
    } else {
      handleScroll(0, 0);
      handleSnackbar('warning', 'validationFail');
    }
  };

  useEffect(() => {
    if (projectDocuments.length !== 0) {
      setIOPFormData({
        ...IOPFormData,
        projectDocumentDetailsList: projectDocuments,
      });
    }
  }, [projectDocuments]);

  const onDocumentChange = async fileDetails => {
    let ifFileExist = find(projectDocuments, [
      'documentName',
      fileDetails?.name,
    ]);

    if (ifFileExist) {
      alert('already added');
    } else {
      const base64Conversion = fileDetails
        ? await convertBase64(fileDetails)
        : '';

      if (base64Conversion) {
        let projectDocs = [
          ...projectDocuments,
          {
            documentName: fileDetails?.name,
            documentSize: fileDetails?.size,
            documentType: fileDetails?.type,
            documentSource: base64Conversion,
            isPrimary: false,
            projectID: externalCampaignMeta.projectID,
          },
        ];
        setProjectDocuments(projectDocs);
      } else {
        setProjectDocuments([]);
      }
    }
  };

  const handleRemove = indexToRemove => {
    const tempProjectDocuments = projectDocuments
      .slice(0, indexToRemove)
      .concat(projectDocuments.slice(indexToRemove + 1));
    setProjectDocuments(tempProjectDocuments);
  };

  const handleCheck = indexToCheck => {
    const tempTwoProjectDocuments = projectDocuments.map((projObj, index) => {
      if (index === indexToCheck) {
        return {
          ...projObj,
          isPrimary: !projObj.isPrimary,
        };
      } else {
        return {
          ...projObj,
          isPrimary: false,
        };
      }
    });
    setProjectDocuments(tempTwoProjectDocuments);
  };

  const makePositive = number => {
    if (number < 0) {
      return Math.abs(number);
    } else {
      return number;
    }
  };

  // const downloadDocument = fileInfo => {
  //   const linkSource = fileInfo.documentSource;
  //   const downloadLink = document.createElement('a');
  //   const fileName = fileInfo.documentName;

  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.target = '_blank';
  //   downloadLink.click();
  // };

  return (
    <div className="container" style={{ marginTop: '20px' }}>
      <Grid container spacing={4}>
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          style={{ paddingRight: '0px', marginTop: '40px' }}
        >
          <span style={{ fontSize: '16px', color: '#888' }}>
            Estimated total investment costs (in USD)
          </span>
        </Grid>
        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            required
            id="standard-required"
            name="totalInvestmentCost"
            label="Total investment cost (USD)"
            value={IOPFormData.totalInvestmentCost}
            onChange={handleChange}
            className={classes.width100}
          />
        </Grid>

        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <div>
            <TextField
              disabled={isReadOnlyMode}
              id="standard-required"
              name="capitalRaised"
              label="Capital raised (USD)"
              value={IOPFormData.capitalRaised}
              onChange={handleChange}
              className={classes.width100}
            />
            {errorState?.capitalRaised ? (
              <span style={{ fontSize: '12px', color: 'red' }}>
                {errorState.capitalRaised}
              </span>
            ) : (
              ''
            )}
          </div>
        </Grid>

        <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
          <TextField
            // type="number"
            // disabled

            disabled={true && isReadOnlyMode}
            id="standard-required"
            name="fundingGap"
            label="Funding gap (USD)"
            value={IOPFormData.totalInvestmentCost - IOPFormData.capitalRaised}
            // onChange={handleChange}
            className={classes.width100}
          />
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <span style={{ fontSize: '14px', color: '#888' }}>
            * Includes the following, land, site preparation and development,
            civil works, structures and buildings, machinery and equipment,
            auxiliary and service plant equipment, incorporated fixed assets,
            pre-production expenditures, knowledge and know-how.
          </span>
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="fiscalIncentivesAvailability"
            label="Availability of fiscal incentives"
            value={IOPFormData.fiscalIncentivesAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.fiscalIncentivesAvailability.length}/2000
          </span> */}
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Available Documents
            </InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="projectDocument"
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={availableDocumentValues}
              onChange={handleChange}
              input={<Input />}
              renderValue={selected => {
                const tempArr = [];
                selected.forEach(selectItem => {
                  const lanObj = availableDocuments.find(
                    obj => obj.value == selectItem
                  );
                  tempArr.push(lanObj?.label);
                });

                return tempArr.join(', ');
              }}
            >
              {availableDocuments?.map(docType => (
                <MenuItem key={docType.value} value={docType.value}>
                  <Checkbox
                    checked={
                      availableDocumentValues?.indexOf(docType.value) > -1
                    }
                  />
                  <ListItemText primary={docType.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-simple-select-label">
              Development Stages
            </InputLabel>
            <Select
              name="projectDevelopmentStage"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={developmentStagesValue}
              margin="normal"
              onChange={handleChange}
            >
              {developmentStages.map(devStage => (
                <MenuItem key={devStage.value} value={devStage.value}>
                  {devStage.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <span style={{ fontSize: '18px', color: '#444' }}>
            Development Stages
          </span>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <div className={classes.developmentStageComponent}>
            {developmentStages.map(devStage => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                // className={
                //   isReadOnlyMode
                //     ? classes.developmentStageBlockDisabled
                //     : classes.developmentStageBlock
                // }
                key={devStage.value}
                className={classes.developmentStageBlock}
                onClick={() => {
                  isReadOnlyMode
                    ? ''
                    : handleChange({
                        target: {
                          name: 'projectDevelopmentStage',
                          value: devStage.value,
                        },
                      });
                }}
              >
                <div
                  className={
                    Number(developmentStagesValue) >= Number(devStage.value)
                      ? classes.developmentStageCircleSelected
                      : classes.developmentStageCircle
                  }
                >
                  {devStage.value}
                </div>
                <div
                  className={
                    Number(developmentStagesValue) >= Number(devStage.value)
                      ? classes.developmentStageLabelSelected
                      : classes.developmentStageLabel
                  }
                >
                  {devStage.label}
                </div>
              </div>
            ))}
            <BorderLinearProgress
              variant="determinate"
              value={
                (Number(developmentStagesValue) * 100) /
                developmentStages.length
              }
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <span style={{ fontSize: '18px', color: '#444' }}>
            Project Enquiry
          </span>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Consent to share Investment Opportunity Profile
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="isConsentToShareIop"
              value={String(IOPFormData.isConsentToShareIop)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Request to be informed before sharing Investment Opportunity
              Profile
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="isRequestToBeInformed"
              value={String(IOPFormData.isRequestToBeInformed)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <span style={{ fontSize: '18px', color: '#444' }}>
            Campaign Admin
          </span>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Does project promote women empowerment
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="isWomenEmpowerment"
              value={String(IOPFormData.isWomenEmpowerment)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl
            component="legend"
            className={classes.width100}
            style={{ display: 'flex' }}
          >
            <FormLabel component="legend">
              Does project promote youth empowerment
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="isYouthEmpowerment"
              value={String(IOPFormData.isYouthEmpowerment)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={isReadOnlyMode} />}
                label="Yes"
              />
              <FormControlLabel
                value="0"
                control={<Radio disabled={isReadOnlyMode} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <FormControl className={classes.width100}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Related Windows of the EFSD
            </InputLabel>
            <Select
              disabled={isReadOnlyMode}
              name="projectEFSDType"
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={EFSDValues}
              onChange={handleChange}
              input={<Input />}
              renderValue={selected => {
                const tempArr = [];
                selected.forEach(selectItem => {
                  const lanObj = EFSDList.find(obj => obj.value == selectItem);
                  tempArr.push(lanObj?.label);
                });

                return tempArr.join(', ');
              }}
            >
              {EFSDList?.map(efsd => (
                <MenuItem key={efsd.value} value={efsd.value}>
                  <Checkbox checked={EFSDValues?.indexOf(efsd.value) > -1} />
                  <ListItemText primary={efsd.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <span>Add Documents</span>
          <br />
          <br />
          <MultiFilePicker
            isReadOnlyMode={isReadOnlyMode}
            onChange={onDocumentChange}
          />
        </Grid>

        {projectDocuments.length > 0 ? (
          <TableContainer component={Paper} style={{ marginLeft: '15px' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: '#00aec8' }}>
                <TableRow>
                  <TableCell align="center" className={classes.tableHead}>
                    Publish
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    Document Name
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDocuments.map((row, index) => {
                  return (
                    <TableRow key={row.documentName}>
                      <TableCell align="center">
                        <FormControlLabel
                          style={{ margin: '0px' }}
                          control={
                            <Checkbox
                              disabled={isReadOnlyMode}
                              checked={row.isPrimary}
                              onClick={() => {
                                handleCheck(index);
                              }}
                              name="checkedA"
                            />
                          }
                          // label="Secondary"
                        />
                      </TableCell>
                      <TableCell align="center">{row.documentName}</TableCell>
                      <TableCell align="center">
                        <a
                          href={row.documentSource}
                          rel="noreferrer"
                          target="_blank"
                          download={row.documentName}
                        >
                          <IconButton
                            className={classes.editButton}
                            variant="contained"
                            // onClick={() => downloadDocument(row)}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </a>
                        <IconButton
                          disabled={isReadOnlyMode}
                          onClick={() => handleRemove(index)}
                          className={classes.removeButton}
                          variant="contained"
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ''
        )}

        {!isReadOnlyMode && (
          <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
            <div className={classes.developmentStageComponent}>
              <FormControlLabel
                label="Please confirm for submission by clicking the checkbox!"
                control={
                  <Checkbox
                    disabled={isReadOnlyMode}
                    checked={IOPFormData.isComplete}
                    onClick={e => handleChange(e, 'isComplete')}
                    name="isComplete"
                    value={String(IOPFormData.isComplete)}
                    inputProps={{ 'aria-label': 'controlled checkbox' }}
                  />
                }
              />
            </div>
            <div style={{ color: '#aaa', fontSize: '14px', marginTop: '10px' }}>
              Note: You will not be able to edit the proposal once submitted by
              clicking the checkbox.
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.btnContainer}
          style={{ paddingRight: '0px' }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              handleScroll(0, 0), setStep(6);
            }}
            className="btn-primary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            onClick={formValidateProject}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <div>
        <Backdrop className={backdropCSS} open={EFSDListLoader}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
}

export default StepEightForm;
