import { has } from 'lodash';

function populateSEGeneralObject(apiData) {
  try {
    const keysObject = {
      name: '',
      postalCode: '',
      address: '',
      externalReference: '',
      website: '',
      yearOfEstablishment: '',
      isSEZ: 0,
      isISO9000: 0,
      isHACCP: 0,
      sourceEntityPurposeId: 1,
      sourceEntityID: '0',
      latitude: '',
      longitude: '',
      companyType: '',
      sourceEntityTypeID: '',
      countryID: '',
      regionID: '',
      otherRegion: '',
      cityID: '',
      otherCity: '',
      sourceLegalStatusID: '',
      sourceEntityLanguage: [],
      sourceEntityContact: [],
      parentSourceEntityID: '',
    };
    const resultObject = {};

    // Iterate over the key-value pairs of the keys object
    Object.entries(keysObject).forEach(([key, value]) => {
      // Check for specific cases first
      if (key === 'sourceEntityTypeID' && apiData.sourceEntityType) {
        // If the key is 'sourceEntityTypeID', and sourceEntityType exists in API data, get its ID
        resultObject[key] = apiData.sourceEntityType.sourceEntityTypeID;
      } else if (key === 'countryID' && apiData.country) {
        // If the key is 'countryID', and country exists in API data, get its ID
        resultObject[key] = apiData.country.countryID;
      } else if (key === 'regionID' && apiData.region) {
        // If the key is 'regionID', and region exists in API data, get its ID
        resultObject[key] = apiData.region.regionID;
      } else if (key === 'cityID' && apiData.city) {
        // If the key is 'cityID', and city exists in API data, get its ID
        resultObject[key] = apiData.city.cityID;
      } else if (key === 'sourceLegalStatusID' && apiData.sourceLegalStatus) {
        // If the key is 'sourceLegalStatusID', and sourceLegalStatus exists in API data, get its ID
        resultObject[key] = apiData.sourceLegalStatus.sourceLegalStatusID;
      } else if (
        key === 'sourceEntityLanguage' &&
        Array.isArray(apiData.sourceEntityLanguage)
      ) {
        // If the key is 'sourceEntityLanguage' and sourceEntityLanguage exists in API data as an array
        // Extract languageID from each object and push it inside an object {languageID: value}
        resultObject[key] = apiData.sourceEntityLanguage.map(languageObj => ({
          languageID: languageObj.language.languageID,
        }));
      } else if (
        key === 'sourceEntityContact' &&
        Array.isArray(apiData.sourceEntityContact)
      ) {
        // If the key is 'sourceEntityContact' and sourceEntityContact exists in API data as an array
        // Loop over each object in the array and extract 'contactDetail' and 'contactTypeID' from each object
        // Push an object containing these values into 'sourceEntityContact' key of our object
        resultObject[key] = apiData.sourceEntityContact.map(contactObj => ({
          contactDetail: contactObj.contactDetail,
          contactTypeID: contactObj.contactType.contactTypeID,
        }));
      } else if (has(apiData, key)) {
        // If the key exists in the API data, assign its value from the API data to the result object
        resultObject[key] = apiData[key];
      } else {
        // If the key does not exist, assign the provided default value to it
        resultObject[key] = value;
      }
    });

    // return resultObject;
    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateSERepresentativeObject(apiData) {
  try {
    const resultObject = {};
    const keysObject = {
      countryID: '',
      regionID: '',
      otherRegion: '',
      cityID: '',
      otherCity: '',
      postalCode: '',
      address: '',
      firstName: '',
      lastName: '',
      titleID: '',
      genderID: '',
      designation: '',
      isPrimary: true,
      //   sourceEntityID: '',
      sourceEntityRepresentativeID: '0',
      sourceEntityRepresentativeContact: [],
    };

    // Check if apiData and keysObject are provided and sourceEntityRepresentative exists in apiData

    const representative = apiData[0]; // Assuming there's only one representative

    // Iterate over the entries of the keysObject array
    Object.entries(keysObject).forEach(([key, value]) => {
      if (
        key === 'sourceEntityRepresentativeContact' &&
        Array.isArray(representative?.sourceEntityRepresentativeContact)
      ) {
        // If the key is 'sourceEntityContact' and sourceEntityContact exists in API data as an array
        // Loop over each object in the array and extract 'contactDetail' and 'contactTypeID' from each object
        // Push an object containing these values into 'sourceEntityContact' key of our object
        resultObject[
          key
        ] = representative.sourceEntityRepresentativeContact.map(
          contactObj => ({
            contactDetail: contactObj.contactDetail,
            contactTypeID: contactObj.contactType.contactTypeID,
            isPrimaryContact: contactObj.isPrimaryContact,
          })
        );
      }
      // Check if the key exists in the representative object
      else if (has(representative, key)) {
        // If the key exists, assign its value from the representative object to the result object
        resultObject[key] = representative[key];
      } else {
        // If the key does not exist, assign the value from keysObject to it in the result object
        resultObject[key] = value;
      }
    });

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPTabOneObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      projectTitle: '',
      projectPlannedDate: '',
      projectVisitedDate: '',
      projectGivenName: null,
      isNonDisclosureAgreement: 'false',
      projectOpportunityScopeDetails: [],
      projectCollaboration: [],
      categoryId: '',
      projectOpportunityTypeID: '',
      otherProjectCollaborationType: '',
      otherProjectOpportunityScope: '',
    };

    // Check if apiData and keysObject are provided
    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData

        // if (apiData.hasOwnProperty(key)) {
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          resultObject[key] = apiData[key];
        } else {
          // If the key does not exist, assign the default value from keysObject to it in the result object
          resultObject[key] = value;
        }
      });

      if (has(apiData, 'isNonDisclosureAgreement')) {
        resultObject['isNonDisclosureAgreement'] = apiData[
          'isNonDisclosureAgreement'
        ]
          ? 'true'
          : 'false';
      }

      if (has(apiData, 'projectPlannedDate')) {
        var getProjectPlannedDate = apiData?.['projectPlannedDate']
          ?.slice(0, 10)
          ?.split('-'); //create an array

        if (getProjectPlannedDate) {
          let tempProjectPlannedDate =
            getProjectPlannedDate[0] +
            '-' +
            getProjectPlannedDate[1] +
            '-' +
            getProjectPlannedDate[2];
          resultObject['projectPlannedDate'] = tempProjectPlannedDate;
        } else {
          resultObject['projectPlannedDate'] = apiData?.['projectPlannedDate'];
        }
      }

      if (has(apiData, 'projectVisitedDate')) {
        var getProjectVisitedDate = apiData?.['projectVisitedDate']
          ?.slice(0, 10)
          ?.split('-'); //create an array

        if (getProjectVisitedDate) {
          var tempProjectVisitedDate =
            getProjectVisitedDate[0] +
            '-' +
            getProjectVisitedDate[1] +
            '-' +
            getProjectVisitedDate[2];

          resultObject['projectVisitedDate'] = tempProjectVisitedDate;
        } else {
          resultObject['projectVisitedDate'] = apiData?.['projectVisitedDate'];
        }
      }

      // Check if "projectOpportunityScopeDetails" key exists in apiData
      if (has(apiData, 'projectOpportunityScopeDetails')) {
        // Extract values for "projectOpportunityScopeDetails" from apiData
        const opportunityScopeDetails =
          apiData['projectOpportunityScopeDetails'];

        // Map over each opportunity scope detail and extract the required keys
        resultObject[
          'projectOpportunityScopeDetails'
        ] = opportunityScopeDetails.map(scopeDetail => ({
          projectOpportunityScopeDetailID:
            scopeDetail['projectOpportunityScopeDetailID'],
          projectID: scopeDetail['projectID'],
          projectOpportunityScopeID:
            scopeDetail['projectOpportunityScope']['projectOpportunityScopeID'],
        }));
      }

      // Check if "projectCollaboration" key exists in apiData
      if (has(apiData, 'projectCollaboration')) {
        // Extract values for "projectCollaboration" from apiData
        const collaborations = apiData['projectCollaboration'];

        // Map over each collaboration and extract the required keys
        resultObject['projectCollaboration'] = collaborations.map(collab => ({
          projectCollaborationID: collab['projectCollaborationID'],
          projectID: collab['projectID'],
          projectCollaborationCategoryID:
            collab['projectCollaborationCategory'][
              'projectCollaborationCategoryID'
            ],
          projectCollaborationTypeID:
            collab['projectCollaborationType']['projectCollaborationTypeID'],
        }));
      }

      if (has(apiData, 'projectOpportunityType')) {
        // Extract values for "projectOpportunityScopeDetails" from apiData
        const equityDetails = apiData['projectOpportunityType'];

        // Map over each opportunity scope detail and extract the required keys
        resultObject['projectOpportunityTypeID'] =
          equityDetails?.projectOpportunityTypeID;
      }
    }

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPTabTwoObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      projectOpportunitySummary: '',
      countryID: '',
      regionID: '',
      otherRegion: '',
      cityID: '',
      otherCity: '',
      address: '',
      companyAndProjectStrengths: '',
      projectSDG: [],
    };

    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          if (key === 'projectSDG') {
            // Extract data from projectSDG key of apiData
            resultObject[key] = apiData[key].map(item => ({
              projectSDGID: item.projectSDGID || 0,
              projectID: item.projectID || 0,
              sdgid: item.sdg.sdgid || 0,
              sdgOrder: item.sdgOrder || 0,
            }));
          } else {
            // For other keys, directly assign the value from apiData to result object
            resultObject[key] = apiData[key];
          }
        } else {
          // If the key does not exist, assign the default value from keysObject to it in the result object
          resultObject[key] = value;
        }
      });
    }

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPTabThreeObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      projectSector: [],
      projectProduct: [],
    };

    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          if (key === 'projectSector') {
            // Extract data from projectSDG key of apiData
            resultObject[key] = apiData[key].map(item => ({
              companySectorCodeID: item.companySectorCode.companySectorCodeID,
              sectorDescription: item.sectorDescription,
              isPrimary: item.isPrimary,
              projectID: item.projectID,
              projectSectorID: 0,
            }));
          }

          if (key === 'projectProduct') {
            // Extract data from projectSDG key of apiData
            resultObject[key] = apiData[key].map(item => ({
              companyProductHSCodeID:
                item.companyProductHSCode.companyProductHSCodeID,
              companyProductUnitID:
                item.companyProductUnit.companyProductUnitID,
              expectedAnnualSales: item.expectedAnnualSales,
              expectedNominalCapacity: item.expectedNominalCapacity,
              percentExportShare: item.percentExportShare,
              percentLocalShare: item.percentLocalShare,
              productDescription: item.productDescription,
              projectID: item.projectID,
              projectProductID: 0,
            }));
          }
        } else {
          // For other keys, directly assign the value from apiData to result object
          resultObject[key] = apiData[key];
        }
      });
    }

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPTabFourObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      marketAvailability: '',
      materialInputAvailability: '',
      technologyAvailability: '',
      infrastructureFacilities: '',
    };

    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          resultObject[key] = apiData[key];
        } else {
          // If the key does not exist, assign the default value from keysObject to it in the result object
          resultObject[key] = value;
        }
      });
    }

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPFiveObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      humanResourcesAvailability: '',
      investmentResourcesAvailability: '',
      projectSkill: [],
    };

    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          if (key === 'projectSkill') {
            // Extract data from projectSDG key of apiData
            resultObject[key] = apiData[key].map(item => ({
              projectSkillID: item.projectSkillID,
              projectID: item.projectID,
              employmentCategoryID:
                item.employmentCategory.employmentCategoryID,
              projectedEmployee: item.projectedEmployee,
            }));
          } else {
            // For other keys, directly assign the value from apiData to result object
            resultObject[key] = apiData[key];
          }
        } else {
          // For other keys, directly assign the value from apiData to result object
          resultObject[key] = value;
        }
      });
    }

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

function populateIOPTabSixObject(apiData) {
  try {
    const resultObject = {};

    const keysObject = {
      fiscalIncentivesAvailability: '',
      capitalRaised: '',
      totalInvestmentCost: '',
      // fundingGap: '',
      projectDocument: [],
      projectDevelopmentStage: [],
      projectEFSDType: [],
      isConsentToShareIop: '',
      isRequestToBeInformed: '',
      isWomenEmpowerment: '',
      isYouthEmpowerment: '',
      projectDocumentDetails: [],
      isComplete: false,
    };

    if (apiData && keysObject) {
      // Iterate over the keysObject
      Object.entries(keysObject).forEach(([key, value]) => {
        // Check if the key exists in the apiData
        if (has(apiData, key)) {
          // If the key exists, assign its value from the apiData to the result object
          if (
            key === 'isConsentToShareIop' ||
            key == 'isRequestToBeInformed' ||
            key == 'isWomenEmpowerment' ||
            key == 'isYouthEmpowerment'
          ) {
            // Extract data from projectSDG key of apiData
            resultObject[key] = apiData[key] == true ? 1 : 0;
          } else if (key === 'projectDocument') {
            resultObject[key] = apiData[key].map(item => ({
              documentTypeID: item.documentType.documentTypeID,
              projectDocumentID: item.projectDocumentID,
              projectID: item.projectID,
            }));
          } else if (key === 'projectDevelopmentStage') {
            resultObject[key] = apiData[key].map(item => ({
              developmentStageID: item.developmentStage.developmentStageID,
              projectDevelopmentStageID: item.projectDevelopmentStageID,
              projectID: item.projectID,
            }));
          } else if (key === 'projectEFSDType') {
            resultObject[key] = apiData[key].map(item => {
              return {
                projectEFSID: 0,
                efsdTypeID: item.efsdTypeID,
                projectID: item.projectID,
              };
            });
          } else if (key === 'projectDocumentDetails') {
            resultObject[key] = apiData[key].map(item => {
              return {
                documentName: item.documentName,
                documentSize: item.documentSize,
                documentSource: item.documentSource,
                documentType: item.documentType,
                isPrimary: item.isPrimary,
                projectDocumentID: item.projectDocumentID,
                projectID: item.projectID,
              };
            });
          } else {
            resultObject[key] = apiData[key];
          }
        } else {
          resultObject[key] = value;
        }
      });
    }

    resultObject['projectDocumentDetailsList'] =
      resultObject['projectDocumentDetails'];

    return { isError: false, result: resultObject, error: null };
  } catch (error) {
    return { isError: true, result: null, error };
  }
}

export {
  populateSEGeneralObject,
  populateSERepresentativeObject,
  populateIOPTabOneObject,
  populateIOPTabTwoObject,
  populateIOPTabThreeObject,
  populateIOPTabFourObject,
  populateIOPFiveObject,
  populateIOPTabSixObject,
};
