import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Constants from '../../../static/Constants';
import { create } from 'apisauce';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import { find, remove, filter, isEmpty } from 'lodash';
import {
  Backdrop,
  CircularProgress,
  Radio,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { populateSERepresentativeObject } from './Helpers/DataFormatters';
import {
  trimString,
  validateSERepresentativeForm,
} from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
  },
  margin0: {
    margin: '0px !important',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

function StepTwoForm({
  countryList,
  externalCampaignMeta,
  handleSnackbar,
  setSourceRepPopup,
  sourceRepPopup,
  setMinSourceRepDetails,
  backdropCSS,
  handleScroll,
  isReadOnlyMode,
}) {
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const classes = useStyles();
  const dialogContentRef = useRef(null);

  const [formData, setFormData] = useState({
    countryID: '',
    regionID: '',
    otherRegion: '',
    cityID: '',
    otherCity: '',
    postalCode: '',
    address: '',
    firstName: '',
    lastName: '',
    titleID: '',
    genderID: '',
    designation: '',
    isPrimary: true,
    sourceEntityID: externalCampaignMeta?.sourceEntityID,
    sourceEntityRepresentativeID: '0',
    sourceEntityRepresentativeContact: [],
  });

  const [regionListLoader, setRegionListLoader] = useState(false);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [seRepresentativeLoader, setSeRepresentativeLoader] = useState(true);
  const [errorState, setErrorState] = useState({});
  const [countryIDSelected, setCountryIDSelected] = useState('');
  const [regionList, setRegionList] = useState([]);
  const [regionIdSelected, setRegionIdSelected] = useState('');
  const [cityList, setCityList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [emailList, setEmailList] = useState([
    {
      contactTypeID: '',
      contactDetail: '',
      isPrimaryContact: true,
    },
  ]);
  const [telephoneList, setTelephoneList] = useState([
    {
      contactTypeID: '',
      contactDetail: '',
      isPrimaryContact: null,
    },
  ]);
  const [socialList, setSocialList] = useState([]);

  useEffect(async () => {
    // if (generalsourceEntityID !== '0') {

    setSeRepresentativeLoader(true);
    try {
      const genderResponse = await apiSauceInstance.get(
        `${Constants.getGenderList}`
      );
      const titleResponse = await apiSauceInstance.get(
        `${Constants.getTitleList}`
      );
      const contactTypeRespnse = await apiSauceInstance.get(
        `${Constants.getContactTypes}`
      );
      const sourceEntityRepresentativeResponse = await apiSauceInstance.get(
        `${Constants.getSourceEntityRepresentative}/${externalCampaignMeta?.sourceEntityID}`
      );

      if (
        (genderResponse.status === 200 || genderResponse.status === 201) &&
        (titleResponse.status === 200 || titleResponse.status === 201) &&
        (contactTypeRespnse.status === 200 ||
          contactTypeRespnse.status === 201) &&
        (sourceEntityRepresentativeResponse.status === 200 ||
          sourceEntityRepresentativeResponse.status === 201)
      ) {
        const genderData = genderResponse.data;
        const titleData = titleResponse.data;
        const contactTypeData = contactTypeRespnse.data;
        const sourceEntityRepresentativeData =
          sourceEntityRepresentativeResponse.data;

        const genderListNew = genderData.map(gender => ({
          value: gender.genderID,
          label: gender.genderCode,
        }));

        const titleListNew = titleData.map(title => ({
          value: title.titleID,
          label: title.titleCode,
        }));

        const contactTypeListNew = contactTypeData.map(contactType => ({
          value: contactType.contactTypeID,
          label: contactType.contactTypeName,
        }));

        setGenderList(genderListNew);
        setTitleList(titleListNew);
        setContactTypeList(contactTypeListNew);

        const formattedFormDataRepresentativeGet = populateSERepresentativeObject(
          sourceEntityRepresentativeData
        );

        if (formattedFormDataRepresentativeGet.isError) {
          handleSnackbar('error', 'error');
          setSeRepresentativeLoader(false);
          return;
        }

        const formDataRepresentativeGet =
          formattedFormDataRepresentativeGet.result;

        let emailID = contactTypeListNew.find(email => email.label == 'Email')
          ?.value;

        const emailListTemp = formDataRepresentativeGet.sourceEntityRepresentativeContact.filter(
          contactObj => contactObj.contactTypeID == emailID
        );

        let telephoneID = contactTypeListNew.find(
          tele => tele.label == 'Telephone'
        )?.value;

        const telephoneListTemp = formDataRepresentativeGet.sourceEntityRepresentativeContact.filter(
          contactObj => contactObj.contactTypeID == telephoneID
        );

        const socialListTemp = formDataRepresentativeGet.sourceEntityRepresentativeContact.filter(
          contactObj =>
            contactObj.contactTypeID !== telephoneID &&
            contactObj.contactTypeID !== emailID
        );

        setFormData({ ...formData, ...formDataRepresentativeGet });

        if (!isEmpty(telephoneListTemp)) {
          setTelephoneList(telephoneListTemp);
        }

        if (!isEmpty(emailListTemp)) {
          setEmailList(emailListTemp);
        }

        setSocialList(socialListTemp);
      } else {
        //error do something
      }

      setSeRepresentativeLoader(false);
    } catch (error) {
      setSeRepresentativeLoader(false);
    }
  }, []);

  useEffect(() => {
    if (countryIDSelected) {
      setRegionListLoader(true);
      apiSauceInstance
        .get(`${Constants.getRegionList}/${countryIDSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const regionListNew = data.map(region => {
              return {
                value: region.regionID,
                label: region.regionName,
              };
            });
            setRegionList(regionListNew);
          } else {
            //error do something
          }

          setRegionListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setRegionListLoader(false);
        });
    }
  }, [countryIDSelected]);

  useEffect(() => {
    if (regionIdSelected) {
      setCityListLoader(true);
      apiSauceInstance
        .get(`${Constants.getCityList}/${regionIdSelected}`)
        .then(({ data, status }) => {
          if (status === 200 || status === 201) {
            const cityListNew = data.map(city => {
              return {
                value: city.cityID,
                label: city.cityName,
              };
            });
            setCityList(cityListNew);
          } else {
            //error do something
          }

          setCityListLoader(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setCityListLoader(false);
        });
    }
  }, [regionIdSelected]);

  useEffect(() => {
    setCountryIDSelected(formData.countryID);
  }, [formData.countryID]);

  useEffect(() => {
    setRegionIdSelected(formData.regionID);
  }, [formData.regionID]);

  const addEmail = () => {
    let tempEmailList = [
      ...emailList,
      {
        contactTypeID: '',
        contactDetail: '',
      },
    ];

    setEmailList(tempEmailList);
  };

  const removeEmail = index => {
    if (!emailList[index]?.isPrimaryContact) {
      let tempEmailList = [...emailList];
      tempEmailList.splice(index, 1);

      setEmailList(tempEmailList);
    }
  };

  const handleEmailChange = (index, event) => {
    let emailID = contactTypeList.find(tele => tele.label == 'Email')?.value;

    let newInputs = [...emailList];

    newInputs[index].contactTypeID = emailID;

    event.target.name == 'emailid'
      ? (newInputs[index].contactDetail = event.target.value)
      : (newInputs[index].isPrimaryContact = event.target.checked);

    if (event.target.name != 'emailid') {
      newInputs = newInputs.map((ele, mapIndex) => {
        return { ...ele, isPrimaryContact: mapIndex == index };
      });
    }

    let errors = { ...errorState };
    remove(errors?.emailList, {
      errorIndex: index,
    });

    if (errors?.emailList?.length == 0) {
      delete errors?.['emailList'];
    }

    setErrorState(errors);
    setEmailList(newInputs);
  };

  const addTelephone = () => {
    let tempTelephoneList = [
      ...telephoneList,
      {
        contactTypeID: '',
        contactDetail: '',
      },
    ];

    setTelephoneList(tempTelephoneList);
  };

  const removeTelephone = index => {
    let tempTelephoneList = [...telephoneList];
    tempTelephoneList.splice(index, 1);

    setTelephoneList(tempTelephoneList);
  };

  const handleTelephoneChange = (index, event) => {
    let telephoneID = contactTypeList.find(tele => tele.label == 'Telephone')
      ?.value;

    const newInputs = [...telephoneList];
    newInputs[index].contactDetail = trimString(event.target.value);
    newInputs[index].contactTypeID = telephoneID;
    newInputs[index].isPrimaryContact = null;

    let errors = { ...errorState };
    remove(errors?.telephoneList, {
      errorIndex: index,
    });

    if (errors?.telephoneList?.length == 0) {
      delete errors?.['telephoneList'];
    }

    setErrorState(errors);
    setTelephoneList(newInputs);
  };

  const addSM = () => {
    let tempSMList = [
      ...socialList,
      {
        contactDetail: '',
        contactTypeID: null,
        mediaTypeDescription: '',
        mediaTypeID: null,
      },
    ];

    setSocialList(tempSMList);
  };

  const removeSM = index => {
    let tempSMList = [...socialList];
    tempSMList.splice(index, 1);

    setSocialList(tempSMList);

    let errors = { ...errorState };

    remove(errors?.socialList, {
      errorIndex: index,
    });

    remove(errors?.socialHandleList, {
      errorIndex: index,
    });

    if (errors?.socialList?.length == 0) {
      delete errors?.['socialList'];
    }

    if (errors?.socialHandleList?.length == 0) {
      delete errors?.['socialHandleList'];
    }

    setErrorState(errors);
  };

  const handleSocialChange = (index, event) => {
    const fieldName = event.target.name;

    const newInputs = [...socialList];

    if (fieldName == 'id') {
      newInputs[index].contactDetail = event.target.value;
    } else {
      newInputs[index].contactTypeID = event.target.value;
      newInputs[index].mediaTypeID = event.target.value;
      newInputs[index].mediaTypeDescription = find(contactTypeList, {
        value: event.target.value,
      })?.label;
    }

    let errors = { ...errorState };

    remove(errors?.socialList, {
      errorIndex: index,
    });

    remove(errors?.socialHandleList, {
      errorIndex: index,
    });

    if (errors?.socialList?.length == 0) {
      delete errors?.['socialList'];
    }

    if (errors?.socialHandleList?.length == 0) {
      delete errors?.['socialHandleList'];
    }

    setErrorState(errors);
    setSocialList(newInputs);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      sourceEntityRepresentativeContact: [
        ...telephoneList,
        ...emailList,
        ...socialList,
      ],
    });
  }, [emailList, telephoneList, socialList]);

  const handleTrim = e => {
    const { name, value } = e.target;
    if (
      name === 'firstName' ||
      name == 'lastName' ||
      name == 'designation' ||
      name == 'address' ||
      name == 'otherCity' ||
      name == 'otherRegion'
    ) {
      setFormData({
        ...formData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let errors = { ...errorState };

    delete errors[name];
    setErrorState(errors);

    if (name == 'countryID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);
      setFormData({
        ...formData,
        [name]: value,
        regionID: '',
        otherRegion: '',
        cityID: '',
        otherCity: '',
      });
      return;
    }

    if (name == 'cityID') {
      delete errors.otherCity;
      setErrorState(errors);
      setFormData({
        ...formData,
        otherCity: '',
      });
    }

    if (name == 'regionID') {
      delete errors.otherRegion;
      delete errors.otherCity;
      setErrorState(errors);

      setFormData({
        ...formData,
        [name]: value,
        cityID: '',
        otherCity: '',
        otherRegion: '',
      });
      return;
    }

    if (name === 'isPrimary') {
      setFormData({
        ...formData,
        [name]: e.target.checked,
      });
    } else if (
      name === 'firstName' ||
      name == 'lastName' ||
      name == 'designation' ||
      name == 'address'
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formValidateRepresentative = () => {
    const errorObj = validateSERepresentativeForm(
      formData,
      emailList,
      telephoneList,
      socialList
    );

    if (!(formData.regionID == 999)) {
      delete errorObj.otherRegion;
    }

    if (!(formData.cityID == 9999)) {
      delete errorObj.otherCity;
    }

    setErrorState(errorObj);
    if (Object.keys(errorObj).length == 0) {
      handleScroll(0, 0);
      handleSave();
    } else {
      if (dialogContentRef.current) {
        dialogContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
      handleSnackbar('warning', 'validationFail');
    }
  };

  const handleSave = () => {
    if (isReadOnlyMode) {
      setSourceRepPopup(false);
    } else {
      setSeRepresentativeLoader(true);
      apiSauceInstance
        .post(`${Constants.postSourceEntityContact}`, [formData])
        .then(({ data, status }) => {
          if (status == 200 || status == 201) {
            setMinSourceRepDetails({
              firstName: formData.firstName,
              lastName: formData.lastName,
              designation: formData.designation,
            });
            handleSnackbar('success', 'success');
          } else {
            handleSnackbar('error', 'error');
          }

          setSeRepresentativeLoader(false);
          setSourceRepPopup(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }

          setSeRepresentativeLoader(false);
        });
    }
  };

  return (
    <Dialog
      open={sourceRepPopup}
      onClose={() => setSourceRepPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Representative Contact Details
      </DialogTitle>
      <DialogContent dividers={'paper'} ref={dialogContentRef}>
        <Grid
          container
          spacing={4}
          className={[classes.width100, classes.margin0]}
          style={{ margin: '0px !important' }}
        >
          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <FormControl
                className={classes.width100}
                style={{ marginTop: '16px' }}
              >
                <InputLabel id="demo-simple-select-label">Title *</InputLabel>
                <Select
                  disabled={isReadOnlyMode}
                  name="titleID"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.titleID}
                  margin="normal"
                  onChange={handleChange}
                >
                  {titleList.map(title => (
                    <MenuItem key={title.value} value={title.value}>
                      {title.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.titleID ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.titleID}
                </span>
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                defaultValue=""
                className={classes.width100}
                inputProps={{ maxLength: 250 }}
              />
              {errorState?.firstName ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.firstName}
                </span>
              ) : (
                ''
              )}
              {/* <span style={{ fontSize: '10px' }}>
                {formData.firstName.length}/250
              </span> */}
            </div>
          </Grid>

          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                onBlur={handleTrim}
                margin="normal"
                defaultValue=""
                className={classes.width100}
                inputProps={{ maxLength: 250 }}
              />
              {errorState?.lastName ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.lastName}
                </span>
              ) : (
                ''
              )}
              {/* <span style={{ fontSize: '10px' }}>
                {formData.lastName.length}/250
              </span> */}
            </div>
          </Grid>

          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <FormControl className={classes.width100}>
                <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                <Select
                  disabled={isReadOnlyMode}
                  name="countryID"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.countryID}
                  margin="normal"
                  onChange={handleChange}
                >
                  {countryList.map(country => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.countryID ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.countryID}
                </span>
              ) : (
                ''
              )}
            </div>
          </Grid>

          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <FormControl
                className={classes.width100}
                disabled={countryIDSelected === ''}
              >
                <InputLabel
                  id={
                    countryIDSelected !== ''
                      ? 'demo-simple-select-label'
                      : 'demo-simple-select-disabled-label'
                  }
                >
                  Region *
                </InputLabel>
                <Select
                  disabled={isReadOnlyMode}
                  name="regionID"
                  label="Region"
                  labelId={
                    countryIDSelected !== ''
                      ? 'demo-simple-select-label'
                      : 'demo-simple-select-disabled-label'
                  }
                  id={
                    countryIDSelected !== ''
                      ? 'demo-simple-select'
                      : 'demo-simple-select-disabled'
                  }
                  value={formData.regionID}
                  margin="normal"
                  onChange={handleChange}
                >
                  {regionList.map(region => (
                    <MenuItem key={region.value} value={region.value}>
                      {region.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.regionID ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.regionID}
                </span>
              ) : (
                ''
              )}

              {formData.regionID === 999 ? (
                <TextField
                  disabled={isReadOnlyMode}
                  required
                  id="standard-required"
                  name="otherRegion"
                  label="Enter Region Name"
                  value={formData.otherRegion}
                  onChange={handleChange}
                  onBlur={handleTrim}
                  margin="normal"
                  className={classes.width100}
                  inputProps={{ maxLength: 50 }}
                />
              ) : (
                ''
              )}
              {errorState?.otherRegion ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.otherRegion}
                </span>
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item md={4} xs={12} sm={4} style={{ paddingRight: '0px' }}>
            <div>
              <FormControl
                className={classes.width100}
                disabled={regionIdSelected === ''}
              >
                <InputLabel
                  id={
                    regionIdSelected !== ''
                      ? 'demo-simple-select-label'
                      : 'demo-simple-select-disabled-label'
                  }
                >
                  City *
                </InputLabel>
                <Select
                  disabled={isReadOnlyMode}
                  name="cityID"
                  label="City"
                  labelId={
                    regionIdSelected !== ''
                      ? 'demo-simple-select-label'
                      : 'demo-simple-select-disabled-label'
                  }
                  id={
                    regionIdSelected !== ''
                      ? 'demo-simple-select'
                      : 'demo-simple-select-disabled'
                  }
                  value={formData.cityID}
                  margin="normal"
                  onChange={handleChange}
                >
                  {cityList.map(city => (
                    <MenuItem key={city.value} value={city.value}>
                      {city.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.cityID ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.cityID}
                </span>
              ) : (
                ''
              )}

              {formData.cityID === 9999 ? (
                <TextField
                  disabled={isReadOnlyMode}
                  required
                  id="standard-required"
                  name="otherCity"
                  label="Enter City Name"
                  value={formData.otherCity}
                  onChange={handleChange}
                  onBlur={handleTrim}
                  margin="normal"
                  className={classes.width100}
                  inputProps={{ maxLength: 50 }}
                />
              ) : (
                ''
              )}
              {errorState?.otherCity ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.otherCity}
                </span>
              ) : (
                ''
              )}
            </div>
          </Grid>

          <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
            <FormControl
              className={classes.width100}
              style={{ marginTop: '16px' }}
            >
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                disabled={isReadOnlyMode}
                name="genderID"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.genderID}
                margin="normal"
                onChange={handleChange}
              >
                {genderList.map(gender => (
                  <MenuItem key={gender.value} value={gender.value}>
                    {gender.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
            <TextField
              disabled={isReadOnlyMode}
              id="standard-required"
              name="designation"
              label="Designation"
              value={formData.designation}
              onChange={handleChange}
              onBlur={handleTrim}
              margin="normal"
              className={classes.width100}
              inputProps={{ maxLength: 250 }}
            />
            {/* <span style={{ fontSize: '10px' }}>
              {formData.designation.length}/250
            </span> */}
          </Grid>

          <Grid item md={6} xs={12} sm={9} style={{ paddingRight: '0px' }}>
            <TextField
              disabled={isReadOnlyMode}
              id="standard-required"
              name="address"
              label="Address"
              value={formData.address}
              onChange={handleChange}
              onBlur={handleTrim}
              margin="normal"
              className={classes.width100}
              inputProps={{ maxLength: 250 }}
            />
            {/* <span style={{ fontSize: '10px' }}>
              {formData.address.length}/250
            </span> */}
          </Grid>

          <Grid item md={6} xs={12} sm={3} style={{ paddingRight: '0px' }}>
            <div>
              <TextField
                disabled={isReadOnlyMode}
                required
                id="standard-required"
                name="postalCode"
                label="Postal Code"
                value={formData.postalCode}
                onChange={handleChange}
                margin="normal"
                className={classes.width100}
              />
              {errorState?.postalCode ? (
                <span style={{ fontSize: '12px', color: 'red' }}>
                  {errorState.postalCode}
                </span>
              ) : (
                ''
              )}
            </div>
          </Grid>

          <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
            Email
            <IconButton
              disabled={isReadOnlyMode}
              onClick={() => addEmail()}
              className={classes.addButton}
              variant="contained"
            >
              <AddCircleIcon />
            </IconButton>
            {emailList.map((value, index) => {
              return (
                <div key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled={isReadOnlyMode}
                      key={index}
                      required
                      id="standard-required"
                      name="emailid"
                      label="Email"
                      value={emailList[index].contactDetail}
                      onChange={event => handleEmailChange(index, event)}
                      margin="normal"
                      className={classes.width100}
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          disabled={isReadOnlyMode}
                          checked={emailList[index].isPrimaryContact == true}
                          onChange={event => handleEmailChange(index, event)}
                          name="emailIsPrimary"
                          size="small"
                        />
                      }
                      label={
                        <Typography style={{ fontSize: '14px' }}>
                          Primary
                        </Typography>
                      }
                    />

                    {emailList.length > 1 && (
                      <IconButton
                        disabled={isReadOnlyMode}
                        onClick={() => removeEmail(index)}
                        // className={classes.addButton}
                        variant="contained"
                      >
                        <HighlightOffIcon
                          style={{ color: isReadOnlyMode ? '#FFBABC' : 'red' }}
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                  </div>
                  {errorState?.emailList
                    ?.filter(errorObj => errorObj.errorIndex === index)
                    .map((obj, errorIndex) => (
                      <p
                        style={{ fontSize: '12px', color: 'red' }}
                        key={errorIndex}
                      >
                        {obj.errorMessage}
                      </p>
                    ))}
                </div>
              );
            })}
          </Grid>

          <Grid item md={6} xs={12} sm={6} style={{ paddingRight: '0px' }}>
            Telephone
            <IconButton
              disabled={isReadOnlyMode}
              onClick={() => addTelephone()}
              className={classes.addButton}
              variant="contained"
            >
              <AddCircleIcon />
            </IconButton>
            {telephoneList.map((value, index) => {
              return (
                <div key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled={isReadOnlyMode}
                      key={index}
                      required
                      id="standard-required"
                      name="telephone"
                      label="Telephone"
                      value={telephoneList[index].contactDetail}
                      onChange={event => handleTelephoneChange(index, event)}
                      margin="normal"
                      className={classes.width100}
                    />

                    {telephoneList.length > 1 && (
                      <IconButton
                        disabled={isReadOnlyMode}
                        onClick={() => removeTelephone(index)}
                        // className={classes.addButton}
                        variant="contained"
                      >
                        <HighlightOffIcon
                          style={{ color: isReadOnlyMode ? '#FFBABC' : 'red' }}
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                  </div>
                  {errorState?.telephoneList
                    ?.filter(errorObj => errorObj.errorIndex === index)
                    .map((obj, errorIndex) => (
                      <span
                        style={{ fontSize: '12px', color: 'red' }}
                        key={errorIndex}
                      >
                        {obj.errorMessage}
                      </span>
                    ))}
                </div>
              );
            })}
          </Grid>
          <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
            <Grid
              container
              spacing={3}
              className={classes.width100}
              md={12}
              xs={12}
              sm={12}
            >
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{ paddingRight: '0px' }}
              >
                Social Media
                <IconButton
                  disabled={isReadOnlyMode}
                  onClick={() => addSM(prevCount => prevCount + 1)}
                  className={classes.addButton}
                  variant="contained"
                >
                  <AddCircleIcon />
                </IconButton>
              </Grid>
              {socialList.map((value, index) => {
                return (
                  <>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      sm={6}
                      style={{ paddingRight: '0px' }}
                    >
                      <FormControl
                        key={index}
                        className={classes.width100}
                        style={{ marginTop: '16px' }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Media Type
                        </InputLabel>
                        <Select
                          disabled={isReadOnlyMode}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={socialList[index].contactTypeID}
                          onChange={event => handleSocialChange(index, event)}
                          name="mediaType"
                          label="Media Type"
                        >
                          {[
                            ...filter(contactTypeList, function(o) {
                              return o.value != 5 && o.value != 6;
                            }),
                          ].map(contactType => (
                            <MenuItem
                              key={contactType.value}
                              value={contactType.value}
                              disabled={find(socialList, {
                                contactTypeID: contactType.value,
                              })}
                            >
                              {contactType.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errorState?.socialHandleList
                        ?.filter(errorObj => errorObj.errorIndex === index)
                        .map((obj, errorIndex) => (
                          <span
                            style={{ fontSize: '12px', color: 'red' }}
                            key={errorIndex}
                          >
                            {obj.errorMessage}
                          </span>
                        ))}
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                      sm={6}
                      style={{ paddingRight: '0px' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          disabled={isReadOnlyMode}
                          id="standard-required"
                          name="id"
                          label="ID"
                          value={socialList[index].contactDetail}
                          onChange={event => handleSocialChange(index, event)}
                          margin="normal"
                          className={classes.width100}
                        />
                        {socialList.length > 0 && (
                          <IconButton
                            disabled={isReadOnlyMode}
                            onClick={() => removeSM(index)}
                            // className={classes.addButton}
                            variant="contained"
                          >
                            <HighlightOffIcon
                              style={{
                                color: isReadOnlyMode ? '#FFBABC' : 'red',
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        )}
                      </div>
                      {errorState?.socialList
                        ?.filter(errorObj => errorObj.errorIndex === index)
                        .map((obj, errorIndex) => (
                          <span
                            style={{ fontSize: '12px', color: 'red' }}
                            key={errorIndex}
                          >
                            {obj.errorMessage}
                          </span>
                        ))}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setSourceRepPopup(false)}
          // onClick={handleSave}
          className="btn-primary"
          variant="contained"
        >
          Close
        </Button>
        {!isReadOnlyMode && (
          <Button
            onClick={formValidateRepresentative}
            // onClick={handleSave}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        )}
      </DialogActions>
      <div>
        <div>
          <Backdrop
            className={backdropCSS}
            open={regionListLoader || cityListLoader || seRepresentativeLoader}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        </div>
      </div>
    </Dialog>
  );
}

export default StepTwoForm;
