import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { trimString } from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '10px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
}));

function StepSixForm({
  setStep,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  const handleTrim = e => {
    const { name, value } = e.target;
    if (
      name === 'marketAvailability' ||
      name == 'materialInputAvailability' ||
      name === 'technologyAvailability' ||
      name == 'infrastructureFacilities'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (
      name == 'marketAvailability' ||
      name == 'materialInputAvailability' ||
      name == 'technologyAvailability' ||
      name == 'infrastructureFacilities'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });

      return;
    }

    setIOPFormData({
      ...IOPFormData,
      [name]: value,
    });
  };

  const handleSave = () => {
    handleIOPSave(IOPFormData, 6);
  };

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Grid container spacing={4} className={classes.width100}>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="marketAvailability"
            label="Availability of market(s)"
            value={IOPFormData.marketAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.marketAvailability.length}/2000
          </span> */}
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="materialInputAvailability"
            label="Availability of material inputs"
            value={IOPFormData.materialInputAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.materialInputAvailability.length}/2000
          </span> */}
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="technologyAvailability"
            label="Availability of technology and know-how"
            value={IOPFormData.technologyAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.technologyAvailability.length}/2000
          </span> */}
        </Grid>

        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="infrastructureFacilities"
            label="Availability of infrastructural facilities at project location"
            value={IOPFormData.infrastructureFacilities}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.infrastructureFacilities.length}/2000
          </span> */}
        </Grid>

        <br />
        <Grid
          item
          xs={12}
          className={classes.btnContainer}
          style={{ paddingRight: '0px' }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              handleScroll(0, 0), setStep(4);
            }}
            className="btn-primary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            onClick={handleSave}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default StepSixForm;
