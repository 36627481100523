import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    justifyContent: 'space-between',
    // height: '65px',
  },
  filePickerButton: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(0, 174, 200)',
      color: '#fff',
    },
  },
  hintText: {
    fontSize: '12px',
    color: '#aaa',
  },
  imageContainer: {
    width: '200px',
    marginBottom: '0px',
    marginTop: '5px',
    borderRadius: '10px',
  },
  blockDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    backgroundColor: '#2c3b8f',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(0, 174, 200)',
      color: '#fff',
    },
  },
}));

const FilePicker = ({
  label = 'Choose File',
  maxFileSizeInMB = 1,
  onChange,
  imageSource,
  isReadOnlyMode,
}) => {
  const classes = useStyles();
  const [imageDetails, setImageDetails] = useState(null);
  const [theInputKey, setTheInputKey] = useState(null);

  const onChangePicker = file => {
    const { files } = file.target;

    if (files.length > 0) {
      const fileSize = files.item(0).size;
      const fileSizeMb = fileSize / 1024 ** 2;

      if (fileSizeMb > maxFileSizeInMB) {
        alert(`Too big! Select a file under ${maxFileSizeInMB} MB!`);
        // input.value = '';
        return false;
      } else {
        setImageDetails(files.item(0));
        onChange(files.item(0));
      }
    }
  };

  const onResetFilePicker = () => {
    let randomString = Math.random().toString(36);
    setImageDetails(null);
    setTheInputKey(randomString);
    onChange(null);
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          {!imageDetails && !imageSource && (
            <Button
              component="label"
              className={
                isReadOnlyMode
                  ? classes.blockDisabled
                  : classes.filePickerButton
              }
              // className={classes.filePickerButton}
            >
              {label}
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                hidden
                onChange={onChangePicker}
                key={theInputKey || ''}
              />
            </Button>
          )}
          {imageSource && (
            <img
              src={imageSource}
              alt="seimage"
              className={classes.imageContainer}
            />
          )}

          {/* <span> {imageDetails?.name}</span> */}
        </div>
        <div>
          {(imageDetails || imageSource) && (
            <IconButton
              disabled={isReadOnlyMode}
              onClick={onResetFilePicker}
              className={classes.addButton}
              variant="contained"
            >
              <CancelIcon />
            </IconButton>
          )}
        </div>
      </div>
      <span className={classes.hintText}>
        Max file size allowed: {maxFileSizeInMB} MB
      </span>
    </>
  );
};

export default FilePicker;
