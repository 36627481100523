import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { trimString } from './Helpers/DataValidators';

const useStyles = makeStyles(theme => ({
  width100: {
    width: '100%',
    marginTop: '10px',
  },
  btnPrimary: {
    backgroundColor: '#2c3b8f',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    padding: '15px 25px',
    borderRadius: '0px',
  },
  addButton: {
    color: '#03AC13',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    fontWeight: '500',
  },
  table: {
    minWidth: 650,
    marginBottom: '0px',
    padding: '10px',
  },
  tableRowTitle: {
    fontSize: '15px',
    padding: '10px !important',
    color: '#fff',
    backgroundColor: '#00aec8',
  },

  addEmpContainer: {
    boxShadow:
      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    marginLeft: '10px',
    padding: '15px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

function StepSevenForm({
  setStep,
  externalCampaignMeta,
  isEditMode,
  backdropCSS,
  setIOPFormData,
  IOPFormData,
  handleIOPSave,
  handleScroll,
  isReadOnlyMode,
}) {
  const classes = useStyles();
  // const apiSauceInstance = create({ baseURL: Constants.apiUrl });
  const [employmentValues, setEmploymentValues] = useState([
    {
      employmentCategoryID: 1,
      employmentCategoryName: 'Managers',
      projectedEmployee: '0',
    },
    {
      employmentCategoryID: 2,
      employmentCategoryName: 'Technical/Supervisory',
      projectedEmployee: '0',
    },
    {
      employmentCategoryID: 3,
      employmentCategoryName: 'Production',
      projectedEmployee: '0',
    },
    {
      employmentCategoryID: 4,
      employmentCategoryName: 'Clerical/Administrative',
      projectedEmployee: '0',
    },
    {
      employmentCategoryID: 5,
      employmentCategoryName: 'Other',
      projectedEmployee: '0',
    },
  ]);

  function bubbleSortByCategoryID(array) {
    const length = array.length;
    for (let i = 0; i < length - 1; i++) {
      for (let j = 0; j < length - 1 - i; j++) {
        if (array[j].employmentCategoryID > array[j + 1].employmentCategoryID) {
          // Swap elements
          [array[j], array[j + 1]] = [array[j + 1], array[j]];
        }
      }
    }
    return array;
  }

  useEffect(() => {
    try {
      if (IOPFormData?.projectSkill.length !== 0) {
        const employmentList = IOPFormData?.projectSkill.map(empInfo => {
          const catName = employmentValues.find(
            obj => empInfo.employmentCategoryID === obj.employmentCategoryID
          ).employmentCategoryName;
          return {
            employmentCategoryID: empInfo.employmentCategoryID,
            employmentCategoryName: catName,
            projectedEmployee: empInfo.projectedEmployee,
          };
        });

        setEmploymentValues(bubbleSortByCategoryID(employmentList));
      }
    } catch (error) {
      if (error) {
        //do something
      }
    }
  }, []);

  const handleTrim = e => {
    const { name, value } = e.target;
    if (
      name == 'humanResourcesAvailability' ||
      name == 'investmentResourcesAvailability'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: trimString(value),
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (
      name === 'Managers' ||
      name === 'Technical/Supervisory' ||
      name == 'Production' ||
      name === 'Clerical/Administrative' ||
      name == 'Other'
    ) {
      setEmploymentValues(
        employmentValues.map(emp => {
          if (emp.employmentCategoryName == name) {
            let cleanValue = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

            // Check if the value consists only of zeros
            if (/^0+$/.test(cleanValue)) {
              cleanValue = '0'; // Set it to '0' if it consists only of zeros
            } else {
              // Remove leading zeros if other digits are present
              cleanValue = cleanValue.replace(/^0+/, '');
            }
            return {
              ...emp,
              projectedEmployee: parseInt(value) < 0 ? 0 : cleanValue,
            };
          } else {
            return emp;
          }
        })
      );

      return;
    } else if (
      name == 'humanResourcesAvailability' ||
      name == 'investmentResourcesAvailability'
    ) {
      setIOPFormData({
        ...IOPFormData,
        [name]: value,
      });

      return;
    }

    setIOPFormData({
      ...IOPFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    const skillArr = employmentValues.map(objEmp => {
      return {
        employmentCategoryID: objEmp.employmentCategoryID,
        projectID: externalCampaignMeta.projectID,
        projectSkillID: 0,
        projectedEmployee:
          objEmp.projectedEmployee == '' ? '0' : objEmp.projectedEmployee,
      };
    });
    setIOPFormData({
      ...IOPFormData,
      projectSkill: skillArr,
    });
  }, [employmentValues]);

  const handleSave = () => {
    handleIOPSave(IOPFormData, 7);
  };

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Grid container spacing={4} className={classes.width100}>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="humanResourcesAvailability"
            label="Availability of human resources at project location"
            value={IOPFormData.humanResourcesAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.humanResourcesAvailability.length}/2000
          </span> */}
        </Grid>

        <Grid container spacing={4} className={classes.addEmpContainer}>
          <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
            <span style={{ fontSize: '16px', color: '#888' }}>
              (Expected additional employment generated by the project)
            </span>
          </Grid>
          {employmentValues.map((obj, index) => {
            return (
              <Grid
                key={index}
                item
                md={6}
                xs={12}
                sm={6}
                style={{ paddingRight: '0px' }}
              >
                <TextField
                  disabled={isReadOnlyMode}
                  id="standard-required"
                  name={obj.employmentCategoryName}
                  label={obj.employmentCategoryName}
                  value={obj.projectedEmployee}
                  onChange={handleChange}
                  className={classes.width100}
                  inputProps={{ maxLength: 9 }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{ paddingRight: '0px' }}>
          <TextField
            disabled={isReadOnlyMode}
            multiline={true}
            rows={5}
            id="standard-required"
            name="investmentResourcesAvailability"
            label="Availability of Investment Resources"
            value={IOPFormData.investmentResourcesAvailability}
            onChange={handleChange}
            onBlur={handleTrim}
            className={classes.width100}
            inputProps={{ maxLength: 1990 }}
          />
          {/* <span style={{ fontSize: '10px' }}>
            {IOPFormData.investmentResourcesAvailability.length}/2000
          </span> */}
        </Grid>
        <br />
        <Grid
          item
          xs={12}
          className={classes.btnContainer}
          style={{ paddingRight: '0px' }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              handleScroll(0, 0), setStep(5);
            }}
            className="btn-primary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            onClick={handleSave}
            className="btn-primary"
            variant="contained"
          >
            {isReadOnlyMode ? 'Next' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default StepSevenForm;
